import { logger } from '@/utils/logging/logger';

export async function unregisterWorkerByScope(scope: string) {
	if ('serviceWorker' in navigator) {
		const sw = navigator.serviceWorker;
		const registrations = await sw.getRegistrations();

		// do not do anything if no registrations are found
		if (registrations.length === 0) {
			return;
		}

		const logInformations = [];

		for await (const registration of registrations) {
			const info = {
				active: registration.active,
				scope: registration.scope,
				wasUnregistered: false,
				unregisterResult: undefined as boolean | undefined,
			};

			if (registration.scope.endsWith(scope)) {
				info.wasUnregistered = true;

				info.unregisterResult = await registration.unregister();
			}
			logInformations.push(info);
		}

		// in production only errors are sent. We only send a fraction to ease the load on the system
		const shouldSendInformation = Math.random() < 0.01;

		if (shouldSendInformation) {
			logger.error(`SW info: ${JSON.stringify(logInformations)}`);
		}
	}
}
