import { useTheme } from 'next-themes';

import NoSSR from '@/cutils/misc/NoSSR';

import { ThemeSelector } from './ThemeSelector';

type Props = { className?: string; forceTheme?: 'light' | 'dark' };

function ThemeSelectorComponent(props: Props) {
	const { theme, setTheme } = useTheme();

	return <ThemeSelector theme={theme} onThemeChange={setTheme} {...props} />;
}

export function ThemeSelectorMenu(props: Props) {
	// theming access via next-themes is only available on the client
	return (
		<NoSSR>
			<ThemeSelectorComponent {...props} />
		</NoSSR>
	);
}
