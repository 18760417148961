import { FeatureFlags } from '@/types/featureFlags';
import { Tracker, TrackEvent } from '@/types/tracking';
import { logger } from '@/utils/logging/logger';

import { GTMAdapter } from './google-tag-manager/GoogleTagManagerTrackingAdapter';
import { isPermittedTrackEvent } from './utils';

type TrackerKey = 'gtm';

export class TrackingRegistry {
	private trackers: Map<TrackerKey, Tracker> = new Map();

	constructor(private googleTagManagerId: string | undefined) {}

	register(tracker: 'gtm') {
		if (typeof window === 'undefined') {
			return;
		}

		switch (tracker) {
			case 'gtm':
				if (!this.trackers.has('gtm') && this.googleTagManagerId) {
					this.trackers.set('gtm', new GTMAdapter());
				}
				break;

			default:
				logger.warn('undefined tracker');
		}
	}

	onEvent = (trackEvent: TrackEvent, featureFlags: FeatureFlags) => {
		const isTrackEventPermitted = isPermittedTrackEvent(trackEvent, featureFlags);

		if (!isTrackEventPermitted) {
			return;
		}

		this.trackers.forEach((tracker) => {
			tracker.onEvent(trackEvent, featureFlags);
		});
	};
}
