import { HTMLAttributes, ReactNode } from 'react';

import styles from './ArticleItemTeaserText.module.scss';

type Props = {
	children: ReactNode;
} & HTMLAttributes<HTMLParagraphElement>;

const ArticleItemTeaserText = ({ children, className: parentClassName, ...rest }: Props) => {
	const className = parentClassName ? `${parentClassName} ${styles.text} ` : styles.text;
	return (
		<p className={`body3 ${className}`} {...rest}>
			{children}
		</p>
	);
};

export default ArticleItemTeaserText;
