import { PageViewProps } from './types';
import useTracktor from './useTracktor';

const PageView = ({ pageViewData }: PageViewProps) => {
	useTracktor({ pageViewData });

	return null;
};

export default PageView;
