import { useToggle } from '@react-hookz/web';
import { usePathname } from 'next/navigation';
import { HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode } from 'react';

import { Link } from '@/components/Link/Link';
import { NavigationButton } from '@/components/base/NavigationButton';
import { IconSystem } from 'src/client/icons/IconSystem';

import { NavigationList } from './NavigationList';
import styles from './NavigationListItem.module.scss';

type NavigationListLinkItemProps = PropsWithChildren<{
	depth: number;
	href: string;
	target?: HTMLAttributeAnchorTarget | undefined;
	icon?: ReactNode;
}>;

type NavigationListNodeItemProps = PropsWithChildren<{
	depth: number;
	title: string;
	className?: string;
}>;

type NavigationListCustomItemProps = PropsWithChildren<{
	className?: string;
	depth: number;
}>;

function NavigationListItem({ children, className }: PropsWithChildren<{ className?: string }>) {
	return (
		<li className={`${styles.navigationListItem} ${className ? className : ''}`} role="menuitem">
			{children}
		</li>
	);
}

export function NavigationListLinkItem({ children, icon, href, target, depth }: NavigationListLinkItemProps) {
	const pathname = usePathname();
	const isLinkActive = pathname === href;

	if (depth > 1) {
		throw new Error('Level greater than 1 not supported');
	}

	return (
		<NavigationListItem className={depth === 1 ? styles.depth1 : ''}>
			<Link className={`${styles.link} ${isLinkActive ? styles.active : ''}`} href={href} target={target}>
				{depth > 0 ? (
					<span className={styles.subpointIcon}>
						<IconSystem icon="subpoint" variant="filled" />
					</span>
				) : null}
				<span className={styles.linkContent}>{children}</span>
				<span className={styles.icon} aria-hidden="true">
					{icon}
				</span>
			</Link>
		</NavigationListItem>
	);
}

export function NavigationListNodeItem({ children, title, depth }: NavigationListNodeItemProps) {
	const [expanded, toggleExpanded] = useToggle(false);

	if (depth > 1) {
		throw new Error('Level greater than 1 not supported');
	}

	return (
		<NavigationListItem className={expanded ? styles.expanded : ''}>
			<NavigationButton text={title} className={styles.button} onClick={toggleExpanded} expanded={expanded} />
			<NavigationList className={styles.nestedNavigationList}>{children}</NavigationList>
		</NavigationListItem>
	);
}

export function NavigationListCustomItem({ className, children, depth }: NavigationListCustomItemProps) {
	if (depth > 1) {
		throw new Error('Level greater than 1 not supported');
	}

	return (
		<NavigationListItem className={`${depth === 1 ? styles.depth1 : ''} ${className ? className : ''}`}>{children}</NavigationListItem>
	);
}
