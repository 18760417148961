// Types
import { logger } from '@/utils/logging/logger';

import { Dispatcher, TrackingData } from '../types';

const defaultDispatcher = function dispatcher(trackingData: TrackingData) {
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line no-console
		logger.info(JSON.stringify(trackingData, null, 2));
	}

	// eslint-disable-next-line no-console
	logger.warn('No <TracktorProvider /> setup.');
} as Dispatcher;

// We'll mark this as the default dispatcher,
// this is used to determine whether the consumer tries to implement multiple `<Provider/>`'s.
defaultDispatcher.isDefault = true;

export default defaultDispatcher;
