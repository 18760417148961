import { HTMLAttributes } from 'react';

import ModuleIcon from '@/components/Icon/ModuleIcon';
import filterRelevantModuleType from '@/cutils/articles/filterRelevantModuleType';
import { IconSystem } from '@/icons/IconSystem';
import { ModuleType } from '@/types/global';

import styles from './ArticleItemModuleTypes.module.scss';

type Props = {
	modulesTypes?: (ModuleType | null)[];
	isExternal?: boolean;
	isBreakingNews?: boolean;
} & HTMLAttributes<HTMLUListElement>;

export function ArticleItemModuleTypes({ modulesTypes, isExternal, isBreakingNews, className, ...rest }: Props) {
	const mappedModuleTypes = modulesTypes
		? modulesTypes.filter(filterRelevantModuleType).map((moduleType) => (
				<li className={styles.listItem} key={moduleType}>
					<ModuleIcon moduleType={moduleType} size="s" />
				</li>
			))
		: null;

	return (
		<ul className={`${styles.list} ${className ?? ''}`} {...rest}>
			{isBreakingNews && (
				<li className={styles.listItem}>
					<IconSystem icon="flash" variant="filled" title="Eilmeldung" size="s" />
				</li>
			)}

			{mappedModuleTypes}

			{isExternal && (
				<li className={styles.listItem}>
					<IconSystem icon="external-link" variant="outline" title="Externer Link" size="s" />
				</li>
			)}
		</ul>
	);
}
