import { useToggle } from '@react-hookz/web';
import { PropsWithChildren } from 'react';

import { NavigationButton } from '@/components/base/NavigationButton';

import styles from './Section.module.scss';
type Props = PropsWithChildren<{
	expandable?: boolean;
	title: string;
	className?: string;
}>;

export function Section({ expandable = true, children, title, className }: Props) {
	const [expanded, setExpanded] = useToggle(true);

	return (
		<section className={`${styles.section} ${className ?? ''}`}>
			<NavigationButton
				className={`heading3 ${styles.navigationButton}`}
				text={title}
				onClick={setExpanded}
				chevronPosition={expanded ? 'up' : 'down'}
				showChevron={expandable}
				expanded={expanded}
			/>
			{!expandable || expanded ? <div className={styles.childWrapper}>{children}</div> : null}
		</section>
	);
}
