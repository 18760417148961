import { useEffect, useState } from 'react';

import { formatTimestamp } from '@/utils/time/formatTimestamp';
import formatTimestampDateTime from '@/utils/time/formatTimestampDateTime';

type Props = {
	className?: string;
	publicationDate?: string | null;
	firstPublicationDate?: string | null;
	formatAbsolute?: boolean;
};

export function ArticleItemPublicationDate({ className, publicationDate, firstPublicationDate, formatAbsolute = false }: Props) {
	const [formattedPublicationDate, setFormattedPublicationDate] = useState(
		formatTimestamp(publicationDate, { isAbsolute: formatAbsolute })
	);

	// update date string when the component is mounted on the client
	useEffect(() => {
		setFormattedPublicationDate(formatTimestamp(publicationDate, { isAbsolute: formatAbsolute }));
	}, [formatAbsolute, publicationDate]);

	if (!publicationDate) {
		return null;
	}
	const dateTimePublicationDate = formatTimestampDateTime(publicationDate);
	const formattedAbsolutePublicationDate = formatTimestamp(publicationDate, { isAbsolute: true });
	const formattedFirstPublicationDate = formatTimestamp(firstPublicationDate);
	return (
		//  On request of Audience Analytics team, we have added static data-first-publication-date to S.PublicationDate, Info: Please consult Audience Analytics team before making any change.
		<time
			className={`aipd-gtm ${className ? className : ''}`}
			dateTime={dateTimePublicationDate || undefined}
			title={formattedAbsolutePublicationDate || undefined}
			data-first-publication-date={formattedFirstPublicationDate && formattedFirstPublicationDate.toString()}
			suppressHydrationWarning={true}
		>
			{formattedPublicationDate}
		</time>
	);
}
