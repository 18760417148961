import React from 'react';
import { Dispatch, ReactNode } from 'react';

export enum MediaContextActionType {
	Pause = 'PAUSE',
	Reset = 'RESET',
	Start = 'START',
	Stop = 'STOP',
}

type MediaContextAction = {
	mediaId: string;
	type: MediaContextActionType;
};

export enum MediaContextPlaybackStatus {
	PAUSED = 'PAUSED',
	STARTED = 'STARTED',
	STOPPED = 'STOPPED',
}

type State = {
	currentMediaId: string | null;
	mediaPlaybackStatus: MediaContextPlaybackStatus;
};

type Context = {
	dispatch: Dispatch<MediaContextAction> | null;
	state: State;
};

const initialState: State = { currentMediaId: null, mediaPlaybackStatus: MediaContextPlaybackStatus.STOPPED };

const reducer = (state: State, action: MediaContextAction): State => {
	switch (action.type) {
		case MediaContextActionType.Pause:
			if (state.currentMediaId !== action.mediaId) {
				return state;
			} else {
				return { ...state, currentMediaId: action.mediaId, mediaPlaybackStatus: MediaContextPlaybackStatus.PAUSED };
			}

		case MediaContextActionType.Reset:
			return initialState;

		case MediaContextActionType.Start:
			return { ...state, currentMediaId: action.mediaId, mediaPlaybackStatus: MediaContextPlaybackStatus.STARTED };

		case MediaContextActionType.Stop:
			if (state.currentMediaId !== action.mediaId) {
				return state;
			} else {
				return { ...state, currentMediaId: action.mediaId, mediaPlaybackStatus: MediaContextPlaybackStatus.STOPPED };
			}
	}
};

const MediaContext = React.createContext<Context>({ state: initialState, dispatch: null });

type MediaContextProviderProps = {
	children: ReactNode;
};

export const MediaContextProvider = ({ children }: MediaContextProviderProps) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);

	return <MediaContext.Provider value={{ dispatch, state }}>{children}</MediaContext.Provider>;
};

export default MediaContext;
