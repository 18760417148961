import { IconSystem } from 'src/client/icons/IconSystem';

import styles from './NavigationButton.module.scss';

type Props = {
	text: string;
	onClick?: () => void;
	className?: string;
	chevronPosition?: 'up' | 'down';
	showChevron?: boolean;
	expanded?: boolean;
};

export function NavigationButton({ text, onClick, className, chevronPosition = 'down', showChevron = true, expanded }: Props) {
	return (
		<button className={`${styles.button} ${className ?? ''}`} onClick={onClick} aria-expanded={expanded} disabled={!showChevron}>
			<span className={styles.text}>{text}</span>
			{showChevron ? (
				<IconSystem className={`${styles.chevron} ${styles[chevronPosition]}`} icon="chevron-down" variant="filled" aria-hidden={true} />
			) : null}
		</button>
	);
}
