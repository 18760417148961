import { ItemList, SiteNavigationElement, WithContext } from 'schema-dts';

import SchemaOrg from '@/components/SchemaOrg';
import { NavigationTree } from '@/cutils/page-context/navigation';
import { isNavigationLeafNode } from '@/cutils/page-context/navigation.utils';

// Recursive
const flattenNavigation = (navigations: NavigationTree[]): { title: string; url: string }[] => {
	const flattenedNavigation = navigations
		.reduce((acc, element) => acc.concat(element), [])
		.reduce(
			(acc, navigationItem) => {
				if (isNavigationLeafNode(navigationItem)) {
					acc.push({ title: navigationItem.title, url: navigationItem.link });
					return acc;
				} else {
					return acc.concat(flattenNavigation([navigationItem.children]));
				}
			},
			[] as { title: string; url: string }[]
		);

	return flattenedNavigation;
};

export function buildNavigationStructuredData(appUrl: string, ...navigation: NavigationTree[]) {
	const navigationLinks = flattenNavigation(navigation)
		.filter((navigationItem) => !!navigationItem.title && !!navigationItem.url)

		// All `SiteNavigationElement`s have to point to one domain, so we'll filter for those after merging both arrays.
		.filter((navigationItem) => navigationItem.url.startsWith('/'));

	const normalizedNavigationItems: SiteNavigationElement[] = navigationLinks
		.filter(({ url }, index, originalLinks) => {
			const urlIndexInOriginalArray = originalLinks.findIndex((originalElement) => originalElement.url === url);
			return urlIndexInOriginalArray === index;
		})
		.map((navigationItem, index) => ({
			'@type': 'SiteNavigationElement',
			name: navigationItem.title,
			position: index + 1,
			url: `${appUrl}${navigationItem.url}`,
		}));

	const schema: WithContext<ItemList> = {
		'@context': 'https://schema.org',
		'@type': 'ItemList',
		itemListElement: normalizedNavigationItems,
		name: 'SiteNavigation',
	};

	return <SchemaOrg schema={schema} />;
}
