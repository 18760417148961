// Types
import { ModuleType } from '@/types/global';

const filterRelevantModuleType = (moduleType: ModuleType | null, _index: number, array: (ModuleType | null)[]) => {
	switch (moduleType) {
		case 'AUDIO':
		case 'GALLERY':
			return true;

		case 'LIVESTREAM':
			// Since we have the same icon for `LIVESTREAM` & `VIDEO`, we'll filter the livestream out if both are present.
			if (array.includes('LIVESTREAM') && array.includes('VIDEO')) {
				return false;
			}

			return true;

		case 'VIDEO':
			return true;

		default:
			return false;
	}
};

export default filterRelevantModuleType;
