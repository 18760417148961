import Script from 'next/script';
import { useMemo } from 'react';

type TDataLayer = Record<string, string>;
type TGtmId = string;
type TAuth = string;
type TPreview = string;

interface Options {
	gtmOrigin?: string;
	gtmId?: TGtmId;
	dataLayer?: TDataLayer;
	/* do not allow custom dataLayerName as we'd have to communicate this to the GoogleTagManagerAdapter */
	// dataLayerName?: TDataLayerName;
	preview?: TPreview;
	auth?: TAuth;
	disabled?: boolean;
	additionalEvents?: Record<string, string>;
}

/**
 * Renders the GoogleTagManager component.
 *
 * @param {Options} gtmOrigin - The origin of the Google Tag Manager.
 * @param {string} gtmId - The ID of the Google Tag Manager.
 * @param {string} auth - The authentication token for the Google Tag Manager.
 * @param {object[]} dataLayer - An array of objects representing the data to be pushed to the data layer.
 * @param {boolean} preview - Whether to enable preview mode for the Google Tag Manager.
 * @param {boolean} disabled - Whether the GoogleTagManager component is disabled.
 * @param {object} additionalEvents - Additional events to be pushed to the data layer.
 * @return {JSX.Element} The rendered GoogleTagManager component.
 */
export const GoogleTagManager = ({ gtmOrigin = 'pa.br.de', gtmId, auth, dataLayer, preview, disabled, additionalEvents = {} }: Options) => {
	const scriptId = 'react-google-tag-manager-gtm';

	const gtm_auth = auth ? `&gtm_auth=${auth}` : '';
	const gtm_preview = preview ? `&gtm_preview=${preview}` : '';

	const scriptContent = useMemo(() => {
		return `
			(function(w,d,s,l,i){
				w[l]=w[l]||[];
				w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(additionalEvents).slice(1, -1)}});
				var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
				j.async=true;j.onerror = function () { var j2 = d.createElement(s); j2.async = true; j2.src = "https://crs.pa.br.de/stats.js?id=" + i + dl; f.parentNode.removeChild(j); f.parentNode.insertBefore(j2, f); };
				j.src='https://${gtmOrigin}/gtm.js?id='+i+dl+'${gtm_auth}${gtm_preview}&gtm_cookies_win=x';
				f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${gtmId}');
		`;
	}, [additionalEvents, gtmId, gtmOrigin, gtm_auth, gtm_preview]);

	if (disabled) {
		return null;
	}

	return (
		<>
			{/* DATALAYER INIT */}
			<Script
				id={`${scriptId}-datalayer`}
				dangerouslySetInnerHTML={{
					__html: `
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push(${JSON.stringify(dataLayer)})
					`,
				}}
			></Script>

			{/* GTM JS ENABLED INIT */}
			<Script
				id={scriptId}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: scriptContent,
				}}
			/>

			{/* GTM JS DISABLED INIT */}
			<noscript>
				<iframe
					src={`https://${gtmOrigin}/ns.html?id=${gtmId}${gtm_auth}${gtm_preview}&gtm_cookies_win=x`}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
					id={`${scriptId}-iframe`}
				/>
			</noscript>
		</>
	);
};
