import { PropsWithChildren, forwardRef } from 'react';

import { getInertValue } from '@/cutils/react/inert-attribute';

import styles from './NavigationList.module.scss';

type Props = PropsWithChildren<{
	className?: string;
	role?: string;
	hidden?: boolean;
	inert?: boolean;
}>;

export const NavigationList = forwardRef<HTMLUListElement, Props>(({ children, className = '', inert, ...rest }, ref) => {
	return (
		<ul ref={ref} className={`${styles.navigationList} heading4 ${className}`} inert={getInertValue(inert)} {...rest}>
			{children}
		</ul>
	);
});
