import { IconSystem } from 'src/client/icons/IconSystem';

import styles from './ScrollToTop.module.scss';

type Props = {
	className?: string;
};

export function ScrollToTop({ className }: Props) {
	return (
		<div className={`${styles.wrapper} ${className}`}>
			<button
				className={`${styles.scrollTopButton} heading4`}
				onClick={() => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}}
			>
				<IconSystem icon="chevron-up" variant="filled" size="l" title="" className={styles.image} />
				<span className={styles.chevronText}> nach oben </span>
			</button>
		</div>
	);
}
