import variables from './breakpoints.module.scss';

/**
 * `sm` breakpoint in pixels
 *
 * @knipignore - not used but should be available for future use */
export const sm = parseInt(variables.sm, 10);

/**
 * `md` breakpoint in pixels
 */
export const md = parseInt(variables.md, 10);

/**
 * `lg` breakpoint in pixels
 */
export const lg = parseInt(variables.lg, 10);

/**
 * `xl` breakpoint in pixels
 */
export const xl = parseInt(variables.xl, 10);
