import { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
	label: string;
	icon: ReactNode;
}>;

export function Tab({ children }: Props) {
	return children;
}
