import { ImageObject, WithContext } from 'schema-dts';

import SchemaOrg from '@/components/SchemaOrg';

import { getObjectFromSearchParams } from '../../misc/url';

type GetModuleImageStructuredDataInput = {
	altText?: string | null;
	copyright?: string | null;
	caption?: string | null;
	url?: string | null;
};

const buildModuleImageStructuredData = ({ altText, copyright, caption, url }: GetModuleImageStructuredDataInput) => {
	if (!(!!altText || !!caption) || !copyright || !url) {
		return null;
	}

	const { h: height = 900, w: width = 1600 } = getObjectFromSearchParams(url);

	const schema: WithContext<ImageObject> = {
		'@context': 'https://schema.org',
		'@type': 'ImageObject',
		caption: altText || caption || undefined,
		width: { '@type': 'QuantitativeValue', value: width, unitCode: 'px' },
		height: { '@type': 'QuantitativeValue', value: height, unitCode: 'px' },
		url,
		copyrightHolder: {
			'@type': 'Person',
			name: copyright,
		},
	};

	return <SchemaOrg schema={schema} />;
};

export default buildModuleImageStructuredData;
