import { ComponentProps, HTMLAttributes } from 'react';

import { ModuleType } from '@/types/global';
import { IconSystem } from 'src/client/icons/IconSystem';

type Props = {
	moduleType: ModuleType | null | string;
	size?: ComponentProps<typeof IconSystem>['size'];
} & HTMLAttributes<SVGElement>;

const ModuleIcon = ({ moduleType, ...rest }: Props) => {
	switch (moduleType) {
		case 'AUDIO':
			return <IconSystem icon="headphones" variant="filled" title="Artikel mit Audio-Inhalten" {...rest} />;

		case 'GALLERY':
			return <IconSystem icon="image-gallery" variant="filled" title="Artikel mit Bildergallerie" {...rest} />;

		case 'LIVESTREAM':
			return <IconSystem icon="livestream" variant="filled" title="Artikel mit Live-Inhalten" {...rest} />;

		case 'VIDEO':
			return <IconSystem icon="video" variant="filled" title="Artikel mit Video-Inhalten" {...rest} />;

		case 'TEXT':
			return <IconSystem icon="content-text" variant="filled" {...rest} />;

		case 'IMAGE':
			return <IconSystem icon="image" variant="filled" title="Artikel mit Bild-Inhalten" {...rest} />;

		default:
			return null;
	}
};

export default ModuleIcon;
