import { TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const typePolicies: TypePolicies = {
	Query: {
		fields: {
			filterArticles: relayStylePagination(['searchPlain']),
			articlesByAuthor: relayStylePagination(['guid']),
		},
	},
};
