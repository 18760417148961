import Head from 'next/head';

import AppleTouchIcon from '../../../../icons/site/apple-touch-icon.png';
import Favicon from '../../../../icons/site/favicon.ico';
import FaviconSVG from '../../../../icons/site/favicon.svg';
import styles from '../../../shared/styles/theme-colors.module.scss';

export function HTMLHead({ baseUrl, appUrl }: { baseUrl: string; appUrl: string }) {
	return (
		<Head>
			{baseUrl && <base href={`${baseUrl}/`} />}

			{/* Meta / SEO */}
			<meta charSet="utf-8" />
			<meta content="ie=edge" httpEquiv="x-ua-compatible" />
			<meta name="apple-mobile-web-app-title" content="BR24" />
			<meta name="application-name" content="BR24" />
			<meta name="msapplication-config" content="/api/browserconfig.xml" />

			{/* Favicons: https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs */}
			<link rel="icon" href={Favicon.src} sizes="32x32" />
			<link rel="icon" href={FaviconSVG.src} type="image/svg+xml" />
			<link rel="apple-touch-icon" href={AppleTouchIcon.src} />
			<link rel="manifest" href="api/web-manifest" />

			{/* This colors the browser-bar on Chrome on Android */}
			{/* The key properties are necessary because otherwise NextJs deduplicates meta tags with the same name */}
			<meta name="theme-color" media="(prefers-color-scheme: light)" key="light-mode" content={styles.themeColorLight} />
			<meta name="theme-color" media="(prefers-color-scheme: dark)" key="dark-mode" content={styles.themeColorDark} />

			<link type="application/opensearchdescription+xml" rel="search" href={`${appUrl}/api/opensearch.xml`} />
		</Head>
	);
}
