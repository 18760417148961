import { PropsWithChildren, createContext, useContext } from 'react';

export interface Environment {
	endpoints: {
		ENDPOINT_REST_API: string;
		ENDPOINT_CORAL: string;
		ENDPOINT_RADIO: string;
		ENDPOINT_GRAPHQL: string;
	};
	flags: {
		IS_WEATHER_HIDDEN: boolean;
		IS_TRAFFIC_HIDDEN: boolean;
		IS_COMMENTS_HIDDEN: boolean;
		IS_RUNDSCHAU_HIDDEN: boolean;
	};
	localStorageKeys: {
		LOCAL_STORAGE_KEY_OUTDATED_BROWSER: string;
		LOCAL_STORAGE_KEY_WEATHER: string;
	};
	keys: {
		GOOGLE_MAPS_API_KEY: string;
		GOOGLE_TAG_MANAGER_ID: string | undefined;
		LIGHTGALLERY_LICENSE_KEY: string;
		USERCENTRICS_ID: string;
	};
	images: {
		DEFAULT_RUNDSCHAU_IMAGE: string;
		FALLBACK_IMAGE: string;
	};
	constants: {
		APP_NAME: string;
		BRANCH: string;
		HOMEPAGE_BOARD_ROW_ID: string;
		BASE_URL: string;
		appUrl: string;
		SELF_ORIGIN: string;
	};
}

const EnvironmentContext = createContext<undefined | Environment>(undefined);

/**
 * EnvironmentProvider component that provides the specified environment to its children.
 *
 * @param {PropsWithChildren<{ environment: Environment }>} children - The children components to render.
 * @param {Environment} environment - The environment object to provide.
 * @return {JSX.Element} The JSX element representing the EnvironmentProvider.
 */
export function EnvironmentProvider({ children, environment }: PropsWithChildren<{ environment: Environment }>) {
	return <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>;
}

/**
 * A custom React Hook that provides access to the environment context.
 *
 * @return {Environment} The environment object from the context.
 */
export function useEnvironment() {
	const environment = useContext(EnvironmentContext);
	if (!environment) {
		throw new Error('You must initialize the environment before using it');
	}
	return environment;
}
