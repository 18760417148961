export function getObjectFromSearchParams(url: URL | string, decode = true, parse = true): Record<string, string | boolean | number | any> {
	try {
		const urlObject = url instanceof URL ? url : new URL(url);
		const searchParamsObject: Record<string, string> = {};
		urlObject.searchParams.forEach((value: string, key: string) => {
			let potentiallyDecodedValue: any = decode ? decodeURI(value) : value;
			if (parse) {
				if (potentiallyDecodedValue.startsWith('{')) {
					try {
						potentiallyDecodedValue = JSON.parse(potentiallyDecodedValue);
						// eslint-disable-next-line no-empty
					} catch {}
				}
				if (potentiallyDecodedValue === 'true' || potentiallyDecodedValue === 'false') {
					potentiallyDecodedValue = Boolean(potentiallyDecodedValue);
				}
				if (/\d/.test(potentiallyDecodedValue)) {
					potentiallyDecodedValue = Number(potentiallyDecodedValue);
				}
			}

			searchParamsObject[key] = potentiallyDecodedValue;
		});
		return searchParamsObject;
	} catch {
		return {};
	}
}
