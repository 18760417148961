import { useEffect, useMemo, useState } from 'react';

import { Breakpoint } from '@/types/enums';

import { mediaQueries } from '../styles/media';

export function useBreakpoints<T extends Record<string, number>>(breakpoints: T): keyof T | undefined {
	if (Object.keys(breakpoints).length < 2) {
		throw new Error('You must supply at least two breakpoints');
	}

	const internalBreakpoints = useMemo(
		() =>
			Object.entries(breakpoints)
				.sort(([, a], [, b]) => a - b)
				.map(([name, value], index, arr) => {
					let query: string;
					if (index === 0) {
						query = `only screen and (width < ${arr[1][1]}px)`;
					} else if (index === arr.length - 1) {
						query = `only screen and (width >= ${value}px)`;
					} else {
						query = `only screen and (width >= ${value}px) and (width < ${arr[index + 1][1]}px)`;
					}

					return { name, value, query };
				}),
		[breakpoints]
	);

	const [currentBreakpoint, setCurrentBreakpoint] = useState<keyof T | undefined>(undefined);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handlers: { mql: MediaQueryList; handler: (e: MediaQueryListEvent) => void }[] = [];

			for (const mediaQuery of internalBreakpoints) {
				const mql = window.matchMedia(mediaQuery.query);

				function listener(e: MediaQueryListEvent) {
					if (e.matches) {
						setCurrentBreakpoint(mediaQuery.name);
					}
				}

				mql.addEventListener('change', listener);

				handlers.push({ mql, handler: listener });

				if (mql.matches) {
					setCurrentBreakpoint(mediaQuery.name);
				}
			}

			return () => {
				for (const { mql, handler } of handlers) {
					mql.removeEventListener('change', handler);
				}
			};
		}

		return undefined;
	}, [internalBreakpoints]);

	return currentBreakpoint;
}

export function useBreakpoint(breakpoint: Breakpoint) {
	const [isMatched, setIsMatched] = useState<boolean>(false);

	const mediaQuery = useMemo(() => mediaQueries.find((mediaquery) => mediaquery.name === breakpoint)?.query, [breakpoint]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (!mediaQuery) {
				setIsMatched(false);
			} else {
				const listener = (e: MediaQueryListEvent) => {
					setIsMatched(e.matches);
				};

				const mql = window.matchMedia(mediaQuery);
				setIsMatched(mql.matches);

				mql.addEventListener('change', listener);

				return () => {
					mql.removeEventListener('change', listener);
				};
			}
		}
		return undefined;
	}, [breakpoint, mediaQuery]);

	return isMatched;
}
