import capitalize from '../string/capitalize';

type BuildImageHoverTitleInput = {
	altText?: string | null;
	copyright?: string | null;
	variant?: 'bild' | 'media';
};

const buildImageHoverTitle = ({ altText, copyright, variant = 'bild' }: BuildImageHoverTitleInput): string | undefined => {
	if (altText) {
		if (copyright) {
			return `${altText} | ${capitalize(variant)}: ${copyright}`;
		}
		return altText;
	}

	if (copyright) {
		return `${capitalize(variant)}: ${copyright}`;
	}

	return undefined;
};

export default buildImageHoverTitle;
