import { TracktorContext } from './TracktorContext';
import { TracktorProps } from './types';
import useTracktor from './useTracktor';
import computeTrackingData from './utils/computeTrackingData';

const Tracktor = ({ children, eventData, intersectionOptions = { triggerOnce: true }, trackingData: ownData = {} }: TracktorProps) => {
	const { createTrackEvent, intersectionRef, onClickWrapper, trackEvent } = useTracktor({
		eventData,
		intersectionOptions,
		trackingData: ownData,
	});

	return (
		// We take the current context, copy it, and forward it to the next `<Tracktor />` with the data from this component merged.
		// This enables us to gradually build the tracking object and have only the appropriate data at each level.
		<TracktorContext.Consumer>
			{(context) => {
				const nextContextValue = { ...context, data: computeTrackingData(context.data, ownData) };

				if (typeof children === 'function') {
					// `children` is a function, call it with the provided functions from `useTracktor`.
					return (
						<TracktorContext.Provider value={nextContextValue}>
							{children({ createTrackEvent, intersectionRef, onClickWrapper, trackEvent })}
						</TracktorContext.Provider>
					);
				}

				return <TracktorContext.Provider value={nextContextValue}>{children}</TracktorContext.Provider>;
			}}
		</TracktorContext.Consumer>
	);
};

export default Tracktor;
