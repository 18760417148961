import { use } from 'react';

const IS_REACT_19 = !!use;

/**
 * Returns the correct value for the `inert` global attribute.
 *
 * React 18 does support it via an empty string, but nextjs already ships types for React Canary
 * which expect a boolean. So we do test for the react version in order to not break when Nextjs
 * ships React 19.
 *
 * @see https://github.com/facebook/react/issues/17157
 * @see https://github.com/facebook/react/issues/17157#issuecomment-2127180687
 *
 * ```
 * Other maintainers check for existence of React.use since that exists in 19 but 18.
 * ```
 *
 * @param value
 * @returns
 */
export function getInertValue(value: boolean | undefined) {
	if (IS_REACT_19) {
		return value;
	}

	if (value === true) {
		return '' as unknown as undefined;
	} else {
		return undefined;
	}
}
