import { ErrorLayout } from './ErrorLayout';
import styles from './ErrorServerError.module.scss';

export function ErrorServerError({ statusCode = 500 }: { statusCode?: number }) {
	return (
		<ErrorLayout>
			<div className={`body1 ${styles.root}`}>
				<div className={`heading1 ${styles.titlePrimary}`}>Allgemeiner Fehler {statusCode} / Interner Serverfehler</div>

				<p>Es ist ein Fehler aufgetreten, unser Server kann Ihre Anfrage gerade nicht beantworten.</p>

				<p>Versuchen Sie es bitte in Kürze noch einmal.</p>
			</div>
		</ErrorLayout>
	);
}
