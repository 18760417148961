// This is bit confusing, but we're typing this manually so we get some sort of typechecking,
// while still being flexible enough so we can call this from anywhere, without having to get all possible module types

type IsBreakingNewsInput = {
	breakingnewsEnd?: string | number | Date;
};

const articleIsBreakingNews = ({ breakingnewsEnd }: IsBreakingNewsInput) => {
	if (breakingnewsEnd && new Date() <= new Date(breakingnewsEnd)) {
		return true;
	}

	return false;
};

export default articleIsBreakingNews;
