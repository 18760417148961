// Types
import { Breakpoint } from '@/types/enums';

const mediaPhoneLarge = `only screen and (min-width: 30.000em)`; //480px
const mediaTabletSmall = `only screen and (min-width: 48.000em)`; //768px
const mediaTabletMedium = `only screen and (min-width: 56.000em)`; //896px
const mediaTabletLarge = `only screen and (min-width: 64.000em)`; //1024px
const mediaTabletExtraLarge = `only screen and (min-width: 80.000em)`; //1280px
const mediaDesktopSmall = `only screen and (min-width: 96.000em)`; //1536px
const mediaDesktopLarge = `only screen and (min-width: 120.000em)`; //1920px

export const mediaQueries = [
	{ name: Breakpoint.PhoneLarge, query: mediaPhoneLarge },
	{ name: Breakpoint.TabletSmall, query: mediaTabletSmall },
	{ name: Breakpoint.TabletMedium, query: mediaTabletMedium },
	{ name: Breakpoint.TabletLarge, query: mediaTabletLarge },
	{ name: Breakpoint.TabletExtraLarge, query: mediaTabletExtraLarge },
	{ name: Breakpoint.DesktopSmall, query: mediaDesktopSmall },
	{ name: Breakpoint.DesktopLarge, query: mediaDesktopLarge },
];
