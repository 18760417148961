import invariant from 'invariant';
import React, { CSSProperties, Children, PropsWithChildren } from 'react';
import { ReactHTML } from 'react';

import styles from './index.module.scss';

export const enum AspectRatioValues {
	RATIO_16_9 = 16 / 9,
	RATIO_16_4 = 16 / 4,
}

type Props = {
	as?: keyof ReactHTML;
	ratio?: AspectRatioValues;
	className?: string;
	style?: CSSProperties;
};

/**
 * The `<AspectRatio />` component does some CSS magic to make elements adhere to a 16:9 or 16:4 ratio.
 * It will wrap a wrapper element (`<div />` by default) and injects needed styles into the child.
 */
function AspectRatio({ as: As = 'div', className, style, children, ratio = AspectRatioValues.RATIO_16_9 }: PropsWithChildren<Props>) {
	// We want to make sure that we only have one child, multiple children would be layed-out on top of each other,
	// so we'll throw an error if you the consumer uses more than one.
	// `invariant` throws if the condition is false, and we're not in production.
	invariant(Children.count(children) === 1, `<AspectRatio /> can only have one direct child.`);

	return (
		<As
			className={`${styles.wrapper} ${ratio === AspectRatioValues.RATIO_16_9 ? styles.aspect169 : styles.aspect164} ${
				className ? className : ''
			}`}
			style={style}
		>
			{children}
		</As>
	);
}

export default AspectRatio;
