import { ALLOWED_IMAGESERVER_HOSTNAMES } from '@/shared/allowed-imageserver-hostnames';

/**
 *
 * @param {Parameters<import('next/image').ImageLoader>[0]} param0
 * @returns
 */
export default function brImageServerLoader({ src, width, quality: _quality }) {
	if (src.indexOf('https://') === 0 || src.indexOf('http://') === 0) {
		const url = new URL(src);

		if (ALLOWED_IMAGESERVER_HOSTNAMES.has(url.hostname)) {
			url.searchParams.set('w', width.toString());
			url.searchParams.set('q', '85');
		}

		return url.toString();
	} else {
		return src;
	}
}
