import { HTMLAttributes, ReactNode } from 'react';

import styles from './ArticleItemBody.module.scss';

type Props = {
	children: ReactNode;
	withVerticalPaddingBottom?: boolean;
} & HTMLAttributes<HTMLElement>;

const ArticleItemBody = ({ children, withVerticalPaddingBottom, className = '', ...rest }: Props) => {
	return (
		<section className={`${styles.body} ${className}`} {...rest}>
			{children}
		</section>
	);
};

export default ArticleItemBody;
