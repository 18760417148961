import { FeatureFlags } from './featureFlags';

type PageViewEventVariant = {
	type: 'PageViewEvent';
	page: string;
};

export type PublisherImpressionVariant = {
	type: 'PublisherImpression';
};

export type PublisherEventVariant = {
	type: 'PublisherEvent';
};

export type PublisherClickVariant = {
	type: 'PublisherClick';
};

export type EventVariant = PageViewEventVariant | PublisherImpressionVariant | PublisherEventVariant | PublisherClickVariant;

export type TrackEvent<Variant = EventVariant> = {
	data?: Record<string, any>;
	event?: Variant;
};

export interface Tracker {
	onEvent(trackEvent: TrackEvent, featureFlags: FeatureFlags): void;
}

export enum TrackEventDataType {
	AuthorArticleClick = 'Autorenartikel',
	ArticleRead = 'Artikel gelesen',
	ArticleRelatedTeasersClick = 'Artikel Das könnte Sie auch interessieren',
	BoardClick = 'Board Klick',
	BoardSectionVisible = 'Board Sektion sichtbar',
	LinkClick = 'BR24 Link-Klick',
	SearchArticleClick = 'Suchergebnis-Artikel',
	TopArticle = 'Top Artikel',
}

export type TrackingEventHandler<Variant = EventVariant, R = any> = (
	trackEvent: Required<TrackEvent<Variant>>,
	options: { featureFlags?: FeatureFlags; trackerId: string }
) => R;
