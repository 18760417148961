// @ts-ignore: autogenerated import
import type * as Types from '../../types/global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TopArticlesQueryVariables = Types.Exact<{
	withCacheTags?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type TopArticlesQuery = {
	__typename?: 'Query';
	topArticles?: {
		__typename?: 'ArticlesConnection';
		nodes: Array<{
			__typename?: 'Article';
			modulesTypes: Array<Types.ModuleType | null>;
			path: string;
			publicationDate?: any | null;
			rowId: string;
			teaserText?: string | null;
			title: string;
			breakingnewsEnd?: any | null;
			withCache?: Array<string> | null;
			image?: {
				__typename?: 'ModuleImage';
				altText?: string | null;
				copyright?: string | null;
				title?: string | null;
				url?: string | null;
			} | null;
		} | null>;
	} | null;
};

export const TopArticlesDocument = gql`
	query TopArticles($withCacheTags: Boolean = false) {
		topArticles: allArticles(orderBy: PUBLICATION_DATE_DESC, first: 10, filter: { status: { equalTo: PUBLISHED } }) {
			nodes {
				modulesTypes
				path
				publicationDate
				rowId
				teaserText
				title
				image {
					altText
					copyright
					title
					url
				}
				breakingnewsEnd
				withCache @include(if: $withCacheTags)
			}
		}
	}
`;

/**
 * __useTopArticlesQuery__
 *
 * To run a query within a React component, call `useTopArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopArticlesQuery({
 *   variables: {
 *      withCacheTags: // value for 'withCacheTags'
 *   },
 * });
 */
export function useTopArticlesQuery(baseOptions?: Apollo.QueryHookOptions<TopArticlesQuery, TopArticlesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TopArticlesQuery, TopArticlesQueryVariables>(TopArticlesDocument, options);
}
export function useTopArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopArticlesQuery, TopArticlesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TopArticlesQuery, TopArticlesQueryVariables>(TopArticlesDocument, options);
}
export function useTopArticlesSuspenseQuery(
	baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopArticlesQuery, TopArticlesQueryVariables>
) {
	const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
	return Apollo.useSuspenseQuery<TopArticlesQuery, TopArticlesQueryVariables>(TopArticlesDocument, options);
}
export type TopArticlesQueryHookResult = ReturnType<typeof useTopArticlesQuery>;
export type TopArticlesLazyQueryHookResult = ReturnType<typeof useTopArticlesLazyQuery>;
export type TopArticlesSuspenseQueryHookResult = ReturnType<typeof useTopArticlesSuspenseQuery>;
export type TopArticlesQueryResult = Apollo.QueryResult<TopArticlesQuery, TopArticlesQueryVariables>;
