import React from 'react';

import { ContextType } from './types';
import defaultDispatcher from './utils/defaultDispatcher';

export const initialState = {
	data: {},
	dispatcher: defaultDispatcher,
};

export const TracktorContext = React.createContext<ContextType>(initialState);
