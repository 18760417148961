import { useEffect } from 'react';

/**
 * Disables vertical scrolling on the given element when the disabled property is set to `true`
 *
 * @param element the element to disable scrolling on
 * @param disabled set to `true` to disable scrolling
 */
export function useImperativeDisableScroll(
	element: HTMLElement | null | undefined,
	disabled: boolean,
	direction: 'vertical' | 'horizontal' | 'both' = 'vertical'
) {
	useEffect(() => {
		if (!element) {
			return;
		}

		let property: 'overflowY' | 'overflowX' | 'overflow' = 'overflowY';

		switch (direction) {
			case 'vertical':
				property = 'overflowY';
				break;
			case 'horizontal':
				property = 'overflowX';
				break;
			case 'both':
				property = 'overflow';
				break;
		}

		if (disabled) {
			element.style[property] = 'hidden';
		} else {
			element.style[property] = '';
		}

		return () => {
			element.style[property] = '';
		};
	}, [direction, disabled, element]);
}
