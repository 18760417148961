import { FeatureFlags } from '../../../types/featureFlags';
import { Tracker, TrackEvent } from '../../../types/tracking';
import { createPublisherEvent } from '../lib/createPublisherEvent';

declare global {
	interface Window {
		dataLayer?: Record<string, any>;
	}
}

const processValue = (value: string): string | number | null => {
	if (!value || value === '') {
		return null;
	}

	// remove [] arround strings
	if (value[0] === '[' && value.slice(-1) === ']') {
		return decodeURIComponent(value.slice(1, -1));
	}

	// otherwise value is an integer
	return parseInt(value, 10) || null;
};

const processCustomVars = (customVars: { [k: string]: any } | null) => {
	return (
		customVars &&
		Object.entries(customVars).reduce((acc, [key, value]) => {
			return { ...acc, [key]: processValue(value) };
		}, {})
	);
};

class GTMAdapter implements Tracker {
	firstPageViewEvent = true;

	onEvent = async (trackEvent: TrackEvent, featureFlags: FeatureFlags): Promise<void> => {
		if (typeof window === 'undefined') {
			return;
		}

		if (!window.dataLayer) {
			window.dataLayer = [];
		}

		const { data, event } = trackEvent;

		if (event && data) {
			if (event.type === 'PageViewEvent') {
				window.dataLayer.push({
					// send following events after the inital load as 'gtm.load' to reset GTM scroll trigger
					event: this.firstPageViewEvent ? 'br24.InitialPageViewEvent' : 'gtm.load',
					...data,
					customVars: processCustomVars(data.customVars),
					...(data.gtmVars || {}),
					...featureFlags,
				});
				this.firstPageViewEvent = false;
			} else if (event.type.startsWith('av.')) {
				window.dataLayer.push({
					// send these events to dataLayer in case of ARD-Player
					event: event.type,
					...data,
					customVars: processCustomVars(data.customVars),
					...(data.gtmVars || {}),
					...featureFlags,
				});
			} else {
				const result = createPublisherEvent({ event, data }, { featureFlags, trackerId: 'GTM' });
				if (result && result.name) {
					window.dataLayer.push({
						event: 'br24.onsite',
						publisher: {
							[result.name]: result.payload,
						},
					});
				}
			}
		}
	};
}

export { GTMAdapter };
