import { useMemo } from 'react';
import { Thing, WithContext } from 'schema-dts';

const JSONLD_CHARACTER_MAPPING: Record<string, string> = {
	'&': '&amp;',
	'<': '&lt;',
	'>': '&gt;',
	'"': '&quot;',
	"'": '&apos;',
};

type Props = {
	schema: WithContext<Thing>;
	serializer?: (key: string, value: string) => string;
};

const defaultReplacer = (char: string) => JSONLD_CHARACTER_MAPPING[char] ?? char;
const defaultReplacePattern = RegExp(`[${Object.keys(JSONLD_CHARACTER_MAPPING).join('')}]`, 'g');

const defaultSerializer = (_key: string, value: string) => {
	if (typeof value === 'string') {
		value = value.replace(defaultReplacePattern, defaultReplacer);
	}

	return value;
};

const SchemaOrg = (props: Props) => {
	const concreteSerializer = useMemo<Props['serializer']>(() => props.serializer || defaultSerializer, [props.serializer]);

	return (
		<script
			type="application/ld+json"
			dangerouslySetInnerHTML={{
				__html: JSON.stringify(props.schema, concreteSerializer),
			}}
		/>
	);
};

export default SchemaOrg;
