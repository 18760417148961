import { RefObject, useEffect } from 'react';

/**
 * Detects if a click occured outside of the passed ref DOM node.
 *
 * Note: If the passed in handler funciton is a new reference on every render, it will cause this effect to do
 * the cleanup and setup logic on every render. To optimize the performance you can wrap the handler funciton in
 * `useCallback` before passing it into this hook.
 *
 * @param ref the reference to the DOM node which serves as boundary for the outside click calcualtion.
 * @param handler a handler that is called when an outside click occurs.
 * @param active set to `true` to activate the outside click handling monitoring
 */
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: (event: MouseEvent | TouchEvent | KeyboardEvent) => void,
	active: boolean,
	ignoredElements: (HTMLElement | undefined)[]
) {
	useEffect(() => {
		if (active) {
			const listener = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
				const element = ref.current;

				// Do nothing if clicking ref's element or descendent elements or ignored elements
				if (
					!element ||
					element.contains(event.target as Node) ||
					ignoredElements.some((element) => element && element.contains(event.target as Node))
				) {
					return;
				}

				handler(event);
			};

			document.addEventListener('mousedown', listener, { capture: true });
			document.addEventListener('touchstart', listener, { capture: true });

			return () => {
				document.removeEventListener('mousedown', listener, { capture: true });
				document.removeEventListener('touchstart', listener, { capture: true });
			};
		} else {
			return undefined;
		}
	}, [ref, handler, active, ignoredElements]);
}
