import { StaticImageData } from 'next/image';

import { NavigationList, NavigationTreeLeaf } from '@/cutils/page-context/navigation';
import { isNavigationLeafNode } from '@/cutils/page-context/navigation.utils';
import { IconVariantMap } from 'src/client/icons/icons-system';

import IconAppleStore from './icons/IconAppleStore.svg';
import IconGooglePlay from './icons/IconGooglePlay.svg';

type LinkItem = {
	title: string;
	url: string;
};

type Icon<IconName extends keyof IconVariantMap = keyof IconVariantMap> = {
	icon: IconName;
};

type IconLinkItem = LinkItem & Icon;
type ImageLinkItem = LinkItem & { icon: StaticImageData };

export const rubrikenLinks: NavigationList = [
	{ id: '1', title: 'Wirtschaft', link: '/wirtschaft,QXAPwyN' },
	{ id: '2', title: 'Kultur', link: '/kultur,QXAPqxI' },
	{ id: '3', title: 'Sport', link: '/sport,QXAPvmL' },
	{ id: '4', title: 'Wissen', link: '/wissen,QXAPyRp' },
	{ id: '5', title: 'Netzwelt', link: '/netzwelt,QXAPuSB' },
];

export const mediaLinks: NavigationList = [
	{
		id: '1',
		title: 'BR24 in der ARD Mediathek',
		link: 'https://www.ardmediathek.de/sendung/br24/Y3JpZDovL2JyLmRlL2Jyb2FkY2FzdFNlcmllcy9icm9hZGNhc3RTZXJpZXM6L2JyZGUvZmVybnNlaGVuL2JheWVyaXNjaGVzLWZlcm5zZWhlbi9zZW5kdW5nZW4vcnVuZHNjaGF1',
		isExternal: true,
	},
	{ id: '2', title: 'BR in der ARD Mediathek', link: 'https://www.ardmediathek.de/br', isExternal: true },
	{
		id: '3',
		title: 'BR24 hören',
		link: 'https://www.br.de/radio/live/br24?autoplay',
		isExternal: true,
	},
	{ id: '4', title: 'Podcasts', link: 'https://br.de/podcast', isExternal: true },
	{ id: '5', title: 'Schlagzeilen', link: '/schlagzeilen' },
];

export const appLinks: ImageLinkItem[] = [
	{ title: 'Apple App Store', icon: IconAppleStore, url: 'https://apps.apple.com/de/app/br24/id998904798 ' },
	{ title: 'Google Play Store', icon: IconGooglePlay, url: 'https://play.google.com/store/apps/details?id=de.br.sep.news.br24' },
];

export const impressumLinks: (NavigationTreeLeaf | { id: string; title: string; type: 'usercentrics' })[] = [
	{ id: '1', title: 'Unternehmen', link: 'https://www.br.de/unternehmen/index.html', isExternal: true },
	{ id: '2', title: 'Impressum', link: 'https://www.br.de/unternehmen/service/impressum/index.html', isExternal: true },
	{ id: '3', title: 'Credits', link: '/credits' },
	{ id: '4', title: 'Datenschutz', link: 'https://www.br.de/unternehmen/service/datenschutz/index.html', isExternal: true },
	{ id: '5', title: 'Datenschutzeinstellungen', type: 'usercentrics' },
	{ id: '6', title: 'Hilfe', link: 'https://www.br.de/service/suche/hilfe/index.html', isExternal: true },
];

export const impressumLinksForStructuredData: NavigationList = impressumLinks.filter((node) =>
	isNavigationLeafNode(node as NavigationTreeLeaf)
) as NavigationList;

export const socialMediaLinks: IconLinkItem[] = [
	{ title: 'Facebook', icon: 'facebook', url: 'https://www.facebook.com/BR24/' },
	{ title: 'X', icon: 'twitter-x', url: 'https://twitter.com/BR24' },
	{ title: 'Instagram', icon: 'instagram', url: 'https://www.instagram.com/br24/' },
	{ title: 'Youtube', icon: 'youtube', url: 'https://www.youtube.com/channel/UCcweJsCV2TUP_kzMX25U-oQ' },
];

export const brLinks: IconLinkItem[] = [
	{ title: 'BR Logo', url: 'https://www.br.de', icon: 'br' },
	{ title: 'ARD Logo', url: 'https://www.ard.de', icon: 'ard' },
];
