import { Component } from 'react';
import { ErrorInfo, ReactNode } from 'react';

import { ErrorServerError } from '@/components/Error/ErrorServerError';
import { logger } from '@/utils/logging/logger';

type Props = {
	children: ReactNode;
	fallback?: ReactNode;
};

type State = {
	hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
	static defaultProps = {
		children: null,
		fallback: null,
	};

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	state = {
		hasError: false,
	};

	componentDidCatch(error: Error, info: ErrorInfo) {
		logger.error(error, info);
	}

	render() {
		const { children, fallback } = this.props;
		const { hasError } = this.state;

		if (hasError) {
			if (!!fallback) {
				return fallback;
			}

			return <ErrorServerError />;
		}

		return children;
	}
}

export default ErrorBoundary;
