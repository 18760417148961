import { PropsWithChildren, useCallback, useRef } from 'react';

import { useFeatureFlags } from '@/components/FeatureFlagsHook';
import { TrackEvent, EventVariant } from '@/types/tracking';
import { TracktorProvider } from 'react-tracktor';

import { DefaultPagePropertiesQuery } from '../page-context/__generated__/default-page-properties.generated';
import { TrackingRegistry } from '../tracking/TrackingRegistry';
import { GoogleTagManager } from '../tracking/google-tag-manager/GoogleTagManagerCustom';

import { useEnvironment } from './EnvironmentContext';

type Props = PropsWithChildren<{ featureFlagData: DefaultPagePropertiesQuery['featureFlags'] }>;

export function TrackingProvider({ children, featureFlagData }: Props) {
	const { keys } = useEnvironment();
	const { flags } = useFeatureFlags(featureFlagData);

	const registryRef = useRef<TrackingRegistry | null>(null);
	if (!registryRef.current) {
		registryRef.current = new TrackingRegistry(keys.GOOGLE_TAG_MANAGER_ID);
		registryRef.current.register('gtm');
	}

	const dispatcher = useCallback(
		(trackingData: TrackEvent<EventVariant>) => {
			if (registryRef.current) {
				registryRef.current.onEvent(trackingData, flags);
			}
		},
		[flags]
	);

	return (
		<>
			<GoogleTagManager gtmOrigin="pa.br.de" gtmId={keys.GOOGLE_TAG_MANAGER_ID} />
			<TracktorProvider dispatcher={dispatcher}>{children}</TracktorProvider>
		</>
	);
}
