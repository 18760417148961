import { useQuery } from '@apollo/client';

import { ImageModule } from '@/components/Image/ImageModule';
import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { TrackEventDataType } from '@/types/tracking';
import articleIsBreakingNews from '@/utils/article/articleIsBreakingNews';
import { logger } from '@/utils/logging/logger';

import { ARTICLE_LIST_ITEM_SIZES, ArticleListItem } from './Article/Items/ArticleListItem';
import styles from './TopArticles.module.scss';
import { TopArticlesDocument, TopArticlesQuery } from './__generated__/TopArticles.generated';

const TopArticles = () => {
	const { data, error, loading } = useQuery<TopArticlesQuery>(TopArticlesDocument);

	if (loading) {
		return null;
	}

	if (error) {
		logger.error(error, 'TopArticles');
	}

	if (!data) {
		logger.error(`No data returned from 'TopArticles'.`);
		return null;
	}

	const topArticles = data.topArticles?.nodes;

	if (!topArticles) {
		logger.error(`No data returned from 'TopArticles'.`);
		return null;
	}

	const mappedTopArticles = topArticles.filter(notNullOrUndefined).map((topArticle) => {
		const isBreakingNews = articleIsBreakingNews(topArticle);

		const modulesTypes = topArticle.modulesTypes.filter(notNullOrUndefined);

		const imageComp = <ImageModule image={topArticle.image} sizes={ARTICLE_LIST_ITEM_SIZES} />;

		return (
			<ArticleListItem
				key={topArticle.rowId}
				className={styles.article}
				as="h3"
				image={imageComp}
				isBreakingNews={isBreakingNews}
				modulesTypes={modulesTypes}
				internalPath={topArticle.path}
				publicationDate={topArticle.publicationDate}
				teaserText={topArticle.teaserText}
				title={topArticle.title}
				trackEventDataType={TrackEventDataType.TopArticle}
			/>
		);
	});

	return (
		<section className={styles.topArticlesRoot}>
			<h2 className={`heading3 ${styles.title}`}>Neuste Artikel</h2>

			{mappedTopArticles}
		</section>
	);
};

export default TopArticles;
