import { differenceInMinutes, differenceInSeconds, format, isSameDay } from 'date-fns';

type FormatTimestampOptions = {
	isAbsolute?: boolean;
};

const ABSOLUTE_FORMAT = "dd.MM.yyyy, HH:mm 'Uhr'";
const TODAY_FORMAT = "'Heute', HH:mm 'Uhr'";

const TWO_MINUTES_IN_SECONDS = 120;
const ONE_HOUR_IN_MINUTES = 60;
const ONE_HOUR_AND_THIRTY_MINUTES_IN_MINUTES = 90;
const THREE_HOURS_AND_THIRTY_MINUTES_IN_MINUTES = 3 * 60 + 30;

/**
 * Formats a timestamp based on the provided options.
 *
 * @param timestamp - The timestamp to format.
 * @param options - The formatting options.
 * @returns The formatted timestamp string or null if no timestamp provided.
 */
export function formatTimestamp(timestamp?: string | number | null, { isAbsolute = false }: FormatTimestampOptions = {}): string | null {
	if (!timestamp) {
		return null;
	}

	const date = new Date(timestamp);

	if (isAbsolute) {
		return format(date, ABSOLUTE_FORMAT);
	}

	const now = new Date(Date.now());
	const diffInMinutes = differenceInMinutes(now, date);

	if (diffInMinutes < 0) {
		// if publication date is in the future -> absolute format
		return format(date, ABSOLUTE_FORMAT);
	}

	if (diffInMinutes < THREE_HOURS_AND_THIRTY_MINUTES_IN_MINUTES) {
		// if now is the same day and difference is less than 4 hours -> use relative format
		return formatRelative(date);
	} else if (isSameDay(now, date)) {
		// otherwise use today format
		return format(date, TODAY_FORMAT);
	} else {
		// if now is not the same day -> use absolute format
		return format(date, ABSOLUTE_FORMAT);
	}
}

const BEFORE_ONE_MINUTE = 'Vor einer Minute';
const BEFORE_ONE_HOUR = 'Vor einer Stunde';

/**
 * Function to format a relative date based on the time gap from the current date.
 *
 * @param date - The date to format relative to the current date.
 * @return The formatted relative date string.
 */
function formatRelative(date: Date) {
	const now = new Date(Date.now());
	const seconds = differenceInSeconds(now, date);

	// everything less than two minutes maps to "before one minute"
	if (seconds < TWO_MINUTES_IN_SECONDS) {
		return BEFORE_ONE_MINUTE;
	}

	// between two minutes and 59 minutes maps to "before x minutes"
	const minutes = differenceInMinutes(now, date);
	if (minutes < ONE_HOUR_IN_MINUTES) {
		return `Vor ${minutes} Minuten`;
	}

	// less than 90 minutes maps to "before one hour"
	if (minutes < ONE_HOUR_AND_THIRTY_MINUTES_IN_MINUTES) {
		return BEFORE_ONE_HOUR;
	} else if (minutes < THREE_HOURS_AND_THIRTY_MINUTES_IN_MINUTES) {
		// rounding for hours
		const hourDisplay = Math.round(minutes / 60);
		return `Vor ${hourDisplay} Stunden`;
	}

	throw new Error('Can not format relative date more than 3 hours and 30 minutes ago');
}
