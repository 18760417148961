import { memo } from 'react';

import { IconPropsWithVariant, IconWithVariants } from './Icon';
import { IconVariantMap } from './icons-system';
import SystemIcons from './icons-system-sprite.svg';

type Props<IconName extends keyof IconVariantMap> = Omit<IconPropsWithVariant<IconVariantMap, IconName>, 'spriteSrc'>;

function IconSystemComponent<IconName extends keyof IconVariantMap>(props: Props<IconName>) {
	return <IconWithVariants<IconVariantMap, IconName> spriteSrc={SystemIcons.src} role="img" {...props} />;
}

export const IconSystem = memo(IconSystemComponent) as typeof IconSystemComponent;
