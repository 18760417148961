import { useKeyboardEvent } from '@react-hookz/web';
import { useTheme } from 'next-themes';
import { ChangeEvent, useEffect, useRef } from 'react';

import { useFocusInside } from '@/cutils/hooks/useFocusInside';
import NoSSR from '@/cutils/misc/NoSSR';

import { RadioButtonIcon } from './RadioButtonIcon';
import styles from './ThemeSelectorFooter.module.scss';

const OptionsKeyboardEvents = new Set(['ArrowUp', 'ArrowDown', 'Home', 'End']);

const Themes = [
	{
		name: 'Systemeinstellungen verwenden',
		value: 'system',
	},
	{
		name: 'Helles Farbschema',
		value: 'light',
	},
	{
		name: 'Dunkles Farbschema',
		value: 'dark',
	},
];

type Props = {
	className?: string;
	forceTheme?: 'dark' | 'light';
};

function ThemeSelectorComponent({ className, forceTheme }: Props) {
	const { theme, setTheme } = useTheme();
	const themeSelectorRef = useRef(null);
	const optionsRef = useRef<(HTMLLabelElement | null)[]>([]);

	const selectedThemeIndex = Themes.findIndex(({ value }) => value === theme);

	const themeSelectorIsFocused = useFocusInside(themeSelectorRef);

	useKeyboardEvent(
		(event) => {
			// handle keyboard events only if theme selector ist focused
			return themeSelectorIsFocused && OptionsKeyboardEvents.has(event.code);
		},
		(e) => {
			const lastChildIndex = Themes.length - 1;
			let themeToActivate = selectedThemeIndex;
			switch (e.code) {
				case 'ArrowUp':
					themeToActivate = Math.max(selectedThemeIndex - 1, 0);
					break;
				case 'ArrowDown':
					themeToActivate = Math.min(selectedThemeIndex + 1, lastChildIndex);
					break;
				case 'Home':
					themeToActivate = 0;
					break;
				case 'End':
					themeToActivate = lastChildIndex;
					break;
			}

			setTheme(Themes[themeToActivate].value);
			e.stopPropagation();
			e.preventDefault();
		},
		[themeSelectorIsFocused, selectedThemeIndex, setTheme]
	);

	useEffect(() => {
		if (themeSelectorIsFocused) {
			optionsRef.current[selectedThemeIndex]?.focus();
		}
	}, [selectedThemeIndex, themeSelectorIsFocused]);

	const changeTheme = (e: ChangeEvent<HTMLInputElement>) => {
		setTheme(e.target.value);
	};

	return (
		<ul className={`${className ?? ''} heading4`} ref={themeSelectorRef} data-theme={forceTheme}>
			{Themes.map(({ value, name }, index) => {
				const isSelected = selectedThemeIndex === index;

				return (
					<li className={styles.themeItem} key={value}>
						<label
							htmlFor={value}
							className={isSelected ? styles.selected : ''}
							tabIndex={isSelected ? 0 : -1}
							ref={(label) => {
								optionsRef.current[index] = label;
							}}
						>
							{name}
							<input type="radio" value={value} id={value} name="theme" checked={isSelected} onChange={changeTheme} tabIndex={-1} />
							<RadioButtonIcon className={styles.checkbox} selected={isSelected} />
						</label>
					</li>
				);
			})}
		</ul>
	);
}

export function ThemeSelectorFooter(props: Props) {
	// theming access is only available on the client
	return (
		<NoSSR>
			<ThemeSelectorComponent {...props} />
		</NoSSR>
	);
}
