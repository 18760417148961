import { useEffect } from 'react';

import { unregisterWorkerByScope } from './unregister';

export function useUnregisterWorkers(scope: string) {
	useEffect(() => {
		if (typeof window !== undefined) {
			unregisterWorkerByScope(scope);
		}
	}, [scope]);
}
