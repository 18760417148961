import { default as NextImage, ImageProps } from 'next/image';
import { CSSProperties } from 'react';

import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { logger } from '@/utils/logging/logger';

import buildImageHoverTitle from '../../utils/builders/buildImageHoverTitle';
import buildModuleImageStructuredData from '../../utils/seo/schema-org/buildModuleImageStructuredData';
import AspectRatio from '../AspectRatio';

import styles from './Image.module.scss';
import DefaultImage from './default-image.png';

export type Props = {
	copyright?: string;
	isPriority?: boolean;
	className?: string;
	style?: CSSProperties;
	alt: string;
	title?: string;
	src: string | undefined | null;
	sizes: string;

	/**
	 * Set to `true` to enable the zooming effect on hover and focus.
	 */
	enableZoomEffect?: boolean;
};

const placeholderImage =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPcXw8AAgMBQLfkYc4AAAAASUVORK5CYII=';

/**
 * Set this class to the element that should enable the zoom effect on the image. The image *must* be a child of the
 * element that has this class set.
 */
export const IMAGE_ZOOM_ON_HOVER_CLASS = styles.imageZoomEnabled;

export function Image({ className = '', style, copyright, isPriority, alt, title, src, enableZoomEffect = false, sizes }: Props) {
	const {
		constants: { appUrl },
	} = useEnvironment();

	if (!src) {
		logger.warn(`Cannot render an image without 'src' set. Using placeholder. [given src: ${src}]`);
		src = DefaultImage.src;
	}

	/* if we have a relative url, prefix with protocol and host */
	if (!src.startsWith('http://') && !src.startsWith('https://')) {
		src = src.startsWith('/') ? `${appUrl}${src}` : `${appUrl}/${src}`;
	}

	// --------------------------------------------------------------------------------------------------------
	// prepare additional meta info
	const hoverTitle = buildImageHoverTitle({ altText: alt, copyright });

	const structuredData =
		typeof title === 'undefined' && typeof copyright === 'undefined'
			? null
			: buildModuleImageStructuredData({
					caption: title,
					altText: alt,
					copyright,
					url: src,
				});

	const defaultImageProps = {
		src,
		alt,
		title: hoverTitle,
		...(isPriority ? { loading: 'eager' as const, priority: true } : { placeholder: 'blur' as const, blurDataURL: placeholderImage }),
	} satisfies ImageProps;

	return (
		<>
			{/* Injects script tags with structured data. */}
			{structuredData}
			<AspectRatio
				style={style ? { ...style, overflow: 'hidden' } : undefined}
				as="figure"
				className={`${enableZoomEffect ? styles.imageZoomEnabled : ''} ${className}`}
			>
				<NextImage fill={true} sizes={sizes} style={{ objectFit: 'cover' }} className={styles.image} {...defaultImageProps} />
			</AspectRatio>
		</>
	);
}
