// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import NextLink from 'next/link';
import { ComponentProps, forwardRef, Ref, useMemo } from 'react';

import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { isLinkExternal, removeAppUrlFromLink } from '@/cutils/links/external-link';

import styles from './Link.module.scss';

const BLANK_REL_ATTRIBUTES = 'noopener';

function getRelForExternalLink(rel: string | undefined) {
	if (rel) {
		return `${rel} ${BLANK_REL_ATTRIBUTES}`;
	} else {
		return BLANK_REL_ATTRIBUTES;
	}
}

type Props = Omit<ComponentProps<typeof NextLink>, 'prefetch'>;

export const Link = forwardRef(function LinkInternal({ href, ...props }: Props, ref: Ref<HTMLAnchorElement>) {
	const {
		constants: { appUrl },
	} = useEnvironment();

	const appUrlRegexp = useMemo(() => {
		return new RegExp(`^([ ]*${appUrl}/)`);
	}, [appUrl]);

	// make internal links relative
	// relative links are subject to client side navigation
	if (typeof href === 'string' && !isLinkExternal(href, appUrlRegexp)) {
		href = removeAppUrlFromLink(href.toString(), appUrlRegexp);
	}

	// setting prefetch to false will prefetch links on hover
	if (props.target === '_blank') {
		const { children, rel, ...linkProps } = props;

		return (
			<NextLink href={href} rel={getRelForExternalLink(rel)} ref={ref} prefetch={false} {...linkProps}>
				{children}
				<span className={styles.hidden}>(öffnet in einem neuen Fenster)</span>
			</NextLink>
		);
	} else {
		return <NextLink href={href} ref={ref} prefetch={false} {...props} />;
	}
});
