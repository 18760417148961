import Image, { ImageProps } from 'next/image';

import styles from './ThemeImage.module.scss';

type Props = Omit<ImageProps, 'src' | 'priority' | 'loading' | 'unoptimized'> & {
	srcLight: string;
	srcDark: string;
};

/**
 * Use this component to display different images in dark and light mode. This us used
 * because safari does not handle prefer-color-scheme media queries within SVG images.
 *
 * @see https://nextjs.org/docs/pages/api-reference/components/image#theme-detection-css
 */
export function ThemeImage(props: Props) {
	const { srcLight, srcDark, className, ...rest } = props;

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<Image {...rest} src={srcLight} className={`${styles.light} ${className ?? ''}`} unoptimized={true} />
			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<Image {...rest} src={srcDark} className={`${styles.dark} ${className ?? ''}`} unoptimized={true} />
		</>
	);
}
