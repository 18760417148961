import { format } from 'date-fns';

/**
 * Formats a timestamp into a specific date and time format.
 *
 * @param timestamp - The timestamp to format. It can be a string representing a date and time, a number representing the number of milliseconds since January 1, 1970, or null.
 * @return The formatted timestamp in the format "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", or null if the timestamp is falsy.
 */
const formatTimestampDateTime = (timestamp?: string | number | null) => {
	if (!timestamp) {
		return null;
	}

	return format(new Date(timestamp), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export default formatTimestampDateTime;
