import throttle from 'lodash/throttle';
import { useState, useEffect, useMemo, useRef } from 'react';

export enum ScrollDirection {
	Up = 'SCROLL_UP',
	Down = 'SCROLL_DOWN',
}

export function useVerticalScrollDirection(threshhold: number, enabled = true) {
	const [direction, setDirection] = useState<ScrollDirection>(ScrollDirection.Up);
	// saving the current scroll position in a ref prevents rerender of the navigation on each scroll event.
	const currentScrollY = useRef<number>(0);

	const handleScroll = useMemo(
		() =>
			throttle(
				() => {
					if (threshhold < Math.abs(window.scrollY - currentScrollY.current)) {
						if (window.scrollY > currentScrollY.current) {
							setDirection(ScrollDirection.Down);
						} else if (window.scrollY < currentScrollY.current) {
							setDirection(ScrollDirection.Up);
						}
						currentScrollY.current = window.scrollY;
					}
				},
				16,
				{ leading: true, trailing: true }
			),

		[currentScrollY, threshhold]
	);

	useEffect(() => {
		if (enabled) {
			if (typeof window !== 'undefined') {
				handleScroll();
				window.addEventListener('scroll', handleScroll, { passive: true });
			}

			return () => {
				handleScroll.cancel();
				window.removeEventListener('scroll', handleScroll);
			};
		} else {
			setDirection(ScrollDirection.Up);
			currentScrollY.current = 0;

			return () => handleScroll.cancel();
		}
	}, [enabled, handleScroll]);

	return direction;
}
