// eslint-disable-next-line import/order
import { AppProps } from 'next/app';
/**
 * Next will combine the CSS of the imported files by import order. In order to load the most important
 * CSS definitions first, we move those CSS imports to the beginning of the file.
 */
// eslint-disable-next-line import/order
import './document.scss';

// eslint-disable-next-line import/order
import { UsercentricsScript } from '@s-group/react-usercentrics';
import { DefaultSeo, NextSeo } from 'next-seo';
import { PropsWithChildren } from 'react';

import ErrorBoundary from '@/components/Error/ErrorBoundary';
import { ErrorGone } from '@/components/Error/ErrorGone';
import { ErrorServerError } from '@/components/Error/ErrorServerError';
import { Footer } from '@/components/Footer/Footer';
import { HTMLHead } from '@/components/HTMLHead/HTMLHead';
import { Header } from '@/components/Header/Header';
import ContextProvider from '@/cutils/context/ContextProvider';
import { useMaintainScrollPosition } from '@/cutils/hooks/useMaintainScrollPosition';
import { AppPageProps } from '@/cutils/page-context/server-side-props';
import { useUnregisterWorkers } from '@/cutils/serviceworker/unregister-workers';
import { useApollo } from '@/utils/apollo/client';
import { theSansC5s } from 'src/client/styles/font';

import styles from './_app.module.scss';

const LIVE_ENVIRONMENT = ['master', 'main'];

function BaseLayout({ pageProps, children }: PropsWithChildren<Pick<AppProps<AppPageProps & { error?: number }>, 'pageProps'>>) {
	const apolloClient = useApollo(pageProps);
	const baseUrl = pageProps.environment.constants.BASE_URL ?? '';
	const appUrl = pageProps.environment.constants.appUrl;

	useMaintainScrollPosition();
	useUnregisterWorkers(`${baseUrl}/`);

	return (
		<>
			<style
				dangerouslySetInnerHTML={{
					__html: `html { font-family: ${theSansC5s.style.fontFamily};}`,
				}}
			></style>

			{/* add `noindex` on non-prod environments */}
			{!LIVE_ENVIRONMENT.includes(pageProps.environment.constants.BRANCH) && (
				<DefaultSeo dangerouslySetAllPagesToNoFollow={true} dangerouslySetAllPagesToNoIndex={true} />
			)}

			{/* Injects static meta tags into the `<head />`. */}
			<HTMLHead baseUrl={baseUrl} appUrl={appUrl} />

			{/* Injects dynamic meta tags into the `<head />`. */}
			<NextSeo {...pageProps.defaultSeoConfig} />

			<ContextProvider apolloClient={apolloClient} featureFlagData={pageProps.featureFlagData} environment={pageProps.environment}>
				<Header navigation={pageProps.navigation} navigationTitle={pageProps.navigationTitle} />

				<main className={`${styles.content} ${theSansC5s.variable}`}>
					<ErrorBoundary fallback={<ErrorServerError />}>{children}</ErrorBoundary>
				</main>
				<Footer bayernNavigation={pageProps.navigation.bayern} />
			</ContextProvider>

			{/* This is the ID for the live configuration, if you need access data to Usercentrics please contact Johanna Borrajoconde */}
			{/* The component will inject a script tag, which will add a data attribute on the script element. So we suppress the warning, as it is ok for this instance */}
			<UsercentricsScript settingsId={pageProps.environment.keys.USERCENTRICS_ID} suppressHydrationWarning={true} />
		</>
	);
}

function MyApp({ Component, pageProps }: AppProps<AppPageProps & { error?: number } & { depublished?: true }>) {
	// if the request results in a non standard error code it will be set here as an additional property
	// therefore we need to display the error page
	if (pageProps.error) {
		return (
			<>
				<NextSeo noindex={true} />

				<BaseLayout pageProps={pageProps}>
					<ErrorServerError statusCode={pageProps.error} />
				</BaseLayout>
			</>
		);
	} else if (pageProps.depublished) {
		return (
			<BaseLayout pageProps={pageProps}>
				<ErrorGone />
			</BaseLayout>
		);
	} else {
		return (
			<BaseLayout pageProps={pageProps}>
				<Component {...pageProps} />
			</BaseLayout>
		);
	}
}

export default MyApp;
