export const enum Breakpoint {
	DesktopLarge = 'DESKTOP_LARGE',
	DesktopSmall = 'DESKTOP_SMALL',
	PhoneLarge = 'PHONE_LARGE',
	TabletMedium = 'TABLET_MEDIUM',
	TabletLarge = 'TABLET_LARGE',
	TabletExtraLarge = 'TABLET_EXTRA_LARGE',
	TabletSmall = 'TABLET_SMALL',
}

export enum MediaControlButtonVariant {
	Audio = 'AUDIO',
	Livestream = 'LIVESTREAM',
	Video = 'VIDEO',
}

export enum PrivacyIcon {
	Default = 'PRIVACY_ICON_DEFAULT',
	Facebook = 'facebook',
	GoogleMaps = 'googlemaps',
	Twitter = 'twitter',
	YouTube = 'youtube',
}

export enum TrafficIcon {
	Accident = 'unfall',
	Caution = 'vorsicht',
	Congestion = 'stau',
	ConstrainedSight = 'sicht',
	Constriction = 'engstelle',
	Construction = 'baustelle',
	Delay = 'wartezeit',
	IcyConditions = 'schnee_eisglaette',
	Information = 'info',
	None = 'keins',
	RestrictedAccess = 'verbot_fahrzeuge',
	SlipHazard = 'schleuder_rutschgefahr',
	SlipHazard2 = 'schleudergefahr',
}

export enum WeatherIcon {
	Clear = 'wolkenlos',
	ClearNight = 'wolkenlos_nacht',
	Cloudy = 'wolkig',
	CloudyNight = 'wolkig_nacht',
	Dewy = 'tauwetter',
	DewyNight = 'tauwetter_nacht',
	Flood = 'hochwasser',
	FloodNight = 'hochwasser_nacht',
	Fog = 'nebel',
	FogFreezing = 'nebel_gefrierend',
	FogFreezingNight = 'nebel_gefrierend_nacht',
	FogNight = 'nebel_nacht',
	ForestFire = 'waldbrand',
	ForestFireNight = 'waldbrand_nacht',
	GroundFog = 'nebel_bodennebel',
	GroundFogNight = 'nebel_bodennebel_nacht',
	Hail = 'hagelschauer',
	HailNight = 'hagelschauer_nacht',
	HailOvercast = 'hagel_bedeckt',
	HailOvercastNight = 'hagel_bedeckt_nacht',
	Hazy = 'dunstig',
	HazyNight = 'dunstig_nacht',
	HighFog = 'nebel_hochnebel',
	HighFogMountains = 'nebel_hochnebel_berge',
	HighFogMountainsNight = 'nebel_hochnebel_berge_nacht',
	HighFogNight = 'nebel_hochnebel_nacht',
	HighFogRain = 'nebel_hochnebel_regen',
	HighFogRainNight = 'nebel_hochnebel_regen_nacht',
	LightOvercast = 'leicht_bewoelkt',
	LightOvercastNight = 'leicht_bewoelkt_nacht',
	LightRainOvercast = 'regen_leicht_bedeckt',
	LightRainOvercastNight = 'regen_leicht_bedeckt_nacht',
	LightSnowOvercast = 'schnee_leicht_bedeckt',
	LightSnowOvercastNight = 'schnee_leicht_bedeckt_nacht',
	NearlyClear = 'fast_wolkenlos',
	NearlyClearNight = 'fast_wolkenlos_nacht',
	Orcan = 'orkan',
	OrcanNight = 'orkan_nacht',
	Overcast = 'bedeckt',
	OvercastNight = 'bedeckt_nacht',
	Rain = 'regenschauer',
	RainClear = 'regenschauer_heiter',
	RainClearNight = 'regenschauer_heiter_nacht',
	RainFreezy = 'regen_gefrierend',
	RainFreezyNight = 'regen_gefrierend_nacht',
	RainNight = 'regenschauer_nacht',
	RainOvercast = 'regen_bedeckt',
	RainOvercastNight = 'regen_bedeckt_nacht',
	RainSleetOvercast = 'schneeregen_bedeckt',
	RainSleetOvercastNight = 'schneeregen_bedeckt_nacht',
	Sleet = 'schneeregenschauer',
	SleetNight = 'schneeregenschauer_nacht',
	Snow = 'schneeschauer',
	SnowClear = 'schneeschauer_heiter',
	SnowClearNight = 'schneeschauer_heiter_nacht',
	SnowNight = 'schneeschauer_nacht',
	SnowOvercast = 'schnee_bedeckt',
	SnowOvercastNight = 'schnee_bedeckt_nacht',
	SoftHail = 'graupelschauer',
	SoftHailClear = 'graupelschauer_heiter',
	SoftHailClearNight = 'graupelschauer_heiter_nacht',
	SoftHailNight = 'graupelschauer_nacht',
	SoftHailOvercast = 'graupel_bedeckt',
	SoftHailOvercastNight = 'graupel_bedeckt_nacht',
	Storm = 'sturm',
	StormNight = 'sturm_nacht',
	StormRain = 'sturm_regen',
	StormRainNight = 'sturm_regen_nacht',
	StrongOvercast = 'stark_bewoelkt',
	StrongOvercastNight = 'stark_bewoelkt_nacht',
	StrongRain = 'regenschauer_stark',
	StrongRainNight = 'regenschauer_stark_nacht',
	StrongRainOvercast = 'regen_stark_bedeckt',
	StrongRainOvercastNight = 'regen_stark_bedeckt_nacht',
	StrongSnow = 'schneeschauer_stark',
	StrongSnowNight = 'schneeschauer_stark_nacht',
	StrongSnowOvercast = 'schnee_stark_bedeckt',
	StrongSnowOvercastNight = 'schnee_stark_bedeckt_nacht',
	ThunderstormDryOvercast = 'gewitter_trocken_bedeckt',
	ThunderstormDryOvercastNight = 'gewitter_trocken_bedeckt_nacht',
	ThunderstormDrySunny = 'gewitter_trocken_sonnig',
	ThunderstormDrySunnyNight = 'gewitter_trocken_sonnig_nacht',
	ThunderstormHailOvercast = 'gewitter_hagel_bedeckt',
	ThunderstormHailOvercastNight = 'gewitter_hagel_bedeckt_nacht',
	ThunderstormLightRain = 'gewitter_regen_leicht',
	ThunderstormLightRainNight = 'gewitter_regen_leicht_nacht',
	ThunderstormRainHail = 'gewitter_regen_hagel',
	ThunderstormRainHailNight = 'gewitter_regen_hagel_nacht',
	ThunderstormRainSunny = 'gewitter_regen_sonnig',
	ThunderstormRainSunnyNight = 'gewitter_regen_sonnig_nacht',
	ThunderstormSoftHailSunny = 'gewitter_graupel_sonnig',
	ThunderstormSoftHailSunnyNight = 'gewitter_graupel_sonnig_nacht',
	ThunderstormStrongRain = 'gewitter_regen_stark',
	ThunderstormStrongRainNight = 'gewitter_regen_stark_nacht',
}
