/*
 * deepmerge-ts uses the `Array.at` method, which is only supported in Safari starting with version 15.4.
 * So we need to polyfill the method when using the deepmerge library.
 *
 */
import 'core-js/actual/array/at';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { deepmerge } from 'deepmerge-ts';

export { deepmerge };
