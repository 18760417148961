import { useLocalStorageValue } from '@react-hookz/web';
import { useEffect, useState } from 'react';

import { Banner } from '@/components/Banner/Banner';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import isIe from '@/cutils/misc/isIe';

type Props = {
	className?: string;
};

export function BannerOutdatedBrowserContainer({ className }: Props) {
	const { localStorageKeys } = useEnvironment();
	const { value: warningIsConfirmed, set: setWarningIsConfirmed } = useLocalStorageValue(
		localStorageKeys.LOCAL_STORAGE_KEY_OUTDATED_BROWSER,
		{
			defaultValue: false,
		}
	);
	const [isVisible, setIsVisible] = useState(false);

	const handleClose = () => {
		setIsVisible(false);
		setWarningIsConfirmed(true);
	};

	useEffect(() => {
		if (isIe() && !warningIsConfirmed) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	}, [warningIsConfirmed]);

	if (!isVisible) {
		return null;
	}

	return (
		<Banner className={className} onClose={handleClose}>
			<p>
				Hinweis: Um alle Funktionen unserer Seiten nutzen zu können, verwenden Sie bitte einen neueren Browser (z.B. die aktuellen Versionen
				von Chrome, Edge, Firefox oder Safari).
			</p>
		</Banner>
	);
}
