import { useNProgress } from '@tanem/react-nprogress';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import isIe from '@/cutils/misc/isIe';

import styles from './LoadingBar.module.scss';

export function LoadingBar() {
	const router = useRouter();

	const [state, setState] = useState({
		isRouteChanging: false,
		loadingKey: 0,
	});

	useEffect(() => {
		const handleRouteChangeStart = () => {
			setState((prevState) => ({
				...prevState,
				isRouteChanging: true,
				loadingKey: prevState.loadingKey ^ 1,
			}));
		};

		const handleRouteChangeEnd = () => {
			setState((prevState) => ({
				...prevState,
				isRouteChanging: false,
			}));
		};

		router.events.on('routeChangeStart', handleRouteChangeStart);
		router.events.on('routeChangeComplete', handleRouteChangeEnd);
		router.events.on('routeChangeError', handleRouteChangeEnd);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);
			router.events.off('routeChangeComplete', handleRouteChangeEnd);
			router.events.off('routeChangeError', handleRouteChangeEnd);
		};
	}, [router.events]);

	const { animationDuration, isFinished, progress } = useNProgress({
		isAnimating: state.isRouteChanging,
	});

	if (isIe()) {
		return null;
	}

	return (
		<div
			className={`${styles.container} ${isFinished ? styles.finished : ''}`}
			style={{ transition: `opacity ${animationDuration}ms linear` }}
		>
			<div
				className={styles.bar}
				style={{ marginLeft: `${(-1 + progress) * 100}%`, transition: `margin-left ${animationDuration}ms linear` }}
			>
				<div className={styles.spinner}></div>
			</div>
		</div>
	);
}
