import { createMedia } from '@artsy/fresnel';
import { RenderProp } from '@artsy/fresnel/dist/Media';
import { ComponentProps, ComponentType, ReactNode } from 'react';

import { lg, md, xl } from '../../../shared/styles/breakpoints';

const AppMedia = createMedia({
	breakpoints: {
		xs: 0,
		md: md,
		lg: lg,
		xl: xl,
	},
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

// typings for children resolve to any https://github.com/artsy/fresnel/pull/372
type FixedMediaProps = Omit<ComponentProps<typeof AppMedia.Media>, 'children'> & { children: ReactNode | RenderProp };

export const BreakpointMedia = AppMedia.Media as ComponentType<FixedMediaProps>;
export const BreakpointMediaContextProvider = AppMedia.MediaContextProvider;
