import { IconSystem } from 'src/client/icons/IconSystem';

import styles from './RadioButtonIcon.module.scss';

type Props = {
	selected: boolean;
	className?: string;
};

export function RadioButtonIcon({ className, selected }: Props) {
	if (selected) {
		return (
			<IconSystem
				icon="radio-button"
				variant="outline"
				className={`${styles.checkbox} ${styles.selected} ${className ?? ''}`}
				aria-label="Radiobuton ausgewählt"
			/>
		);
	} else {
		return <IconSystem icon="circle" variant="outline" className={`${styles.checkbox} ${className ?? ''}`} aria-label="Radiobuton" />;
	}
}
