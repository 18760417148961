import { RefObject, useCallback, useEffect, useState } from 'react';

export function useFocusInside(ref: RefObject<HTMLElement>) {
	const [focused, setFocused] = useState(false);

	const handleFocusIn = useCallback(() => {
		setFocused(true);
	}, []);

	const handleFocusOut = useCallback(() => {
		setFocused(false);
	}, []);

	useEffect(() => {
		const element = ref.current;

		if (element) {
			element.addEventListener('focusin', handleFocusIn);
			element.addEventListener('focusout', handleFocusOut);
			return () => {
				element.removeEventListener('focusin', handleFocusIn);
				element.removeEventListener('focusout', handleFocusOut);
			};
		}

		return undefined;
	}, [handleFocusIn, handleFocusOut, ref]);

	return focused;
}
