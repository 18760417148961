// Types

import { FeatureFlags } from '@/types/featureFlags';
import { TrackEvent, TrackEventDataType } from '@/types/tracking';

import formatInteger from '../integer/formatInteger';

type BuildSectionPlacementVariableInput = {
	inSectionPlacement?: number | null;
	sectionPlacement?: number | null;
};

export const buildSectionPlacementVariable = ({ inSectionPlacement, sectionPlacement }: BuildSectionPlacementVariableInput) => {
	if (typeof inSectionPlacement !== 'number' || typeof sectionPlacement !== 'number') {
		return null;
	}

	const sectionPlacementVariable = `${formatInteger(sectionPlacement, 0, 2)}-${inSectionPlacement}`;

	return sectionPlacementVariable;
};

const getTrackEventType = (trackEvent: TrackEvent) => {
	const type = trackEvent?.data?.type as TrackEventDataType | null | undefined;

	if (!type || typeof type !== 'string') {
		return null;
	}

	return type;
};

export const isPermittedTrackEvent = (trackEvent: TrackEvent, featureFlags: FeatureFlags) => {
	const trackEventType = getTrackEventType(trackEvent);

	switch (trackEventType) {
		case TrackEventDataType.BoardSectionVisible:
			if (!featureFlags.tracking || !featureFlags.tracking.boardSection) {
				return false;
			}

			return true;

		default:
			return true;
	}
};
