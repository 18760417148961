import { Banner } from '../../Banner/Banner';

type Props = {
	className?: string;
};

export function BannerNoJavaScript({ className }: Props) {
	return (
		<noscript>
			<Banner className={className}>
				<p>Hinweis: Um alle Funktionen unserer Seiten nutzen zu können, wird JavaScript empfohlen.</p>
			</Banner>
		</noscript>
	);
}
