// Source: https://github.com/kadirahq/react-no-ssr / https://github.com/JeromeFitz/react-no-ssr

import React from 'react';
import { ReactNode } from 'react';

const DefaultPlaceholder = () => <React.Fragment />;

type Props = {
	children: ReactNode;
	placeholder?: ReactNode;
};

type State = {
	canRender: boolean;
};

class NoSSR extends React.Component<Props, State> {
	state = {
		canRender: false,
	};

	componentDidMount() {
		this.setState({ canRender: true });
	}

	render() {
		const { children, placeholder = <DefaultPlaceholder /> } = this.props;
		const { canRender } = this.state;

		return canRender ? children : placeholder;
	}
}

export default NoSSR;
