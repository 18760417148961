/* eslint-disable @typescript-eslint/unified-signatures */
import { HTMLAttributes, ReactHTML, ReactNode } from 'react';

import { IMAGE_ZOOM_ON_HOVER_CLASS } from '@/components/Image/Image';
import { Link } from '@/components/Link/Link';
import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { md, xl } from '@/shared/styles/breakpoints';
import { ModuleType } from '@/types/global';
import { TrackEventDataType } from '@/types/tracking';
import { Tracktor } from 'react-tracktor';

import ArticleItemBody from './ArticleItemBody';
import { ArticleItemModuleTypes } from './ArticleItemModuleTypes';
import { ArticleItemPublicationDate } from './ArticleItemPublicationDate';
import ArticleItemTeaserText from './ArticleItemTeaserText';
import styles from './ArticleListItem.module.scss';

export const ARTICLE_LIST_ITEM_SIZES = `(max-width: ${md}px) 100vw, (max-width: ${xl}px) 400px, 400px`;

type BaseProps = {
	as?: keyof ReactHTML;
	image: ReactNode;
	publicationDate?: string | null;
	teaserText?: string | null;
	title: string;
	trackEventDataType: TrackEventDataType;
} & HTMLAttributes<HTMLAnchorElement>;

type ExternalLinkProps = {
	/**
	 * If `url` is specified the link will be marked as external link
	 */
	externalUrl: string;
} & BaseProps;

type InternalLinkProps = {
	/**
	 * If `path` is specified the link will be marked as internal link
	 */
	internalPath: string;

	/**
	 * Set to `true` if the article is a breaking news article.
	 */
	isBreakingNews: boolean;

	/**
	 * The module types to display for internal links.
	 */
	modulesTypes: (ModuleType | null)[];
} & BaseProps;

export function ArticleListItem(props: InternalLinkProps): JSX.Element;
export function ArticleListItem(props: ExternalLinkProps): JSX.Element;
export function ArticleListItem(props: InternalLinkProps | ExternalLinkProps) {
	const { constants } = useEnvironment();
	const { as: As = 'h2', image, publicationDate, teaserText, title, trackEventDataType, className } = props;

	let trackingUrl: string;
	let href: string;
	let isExternal: boolean;
	let isBreakingNews: boolean;
	let modulesTypes: (ModuleType | null)[];
	if ('internalPath' in props) {
		href = props.internalPath;
		trackingUrl = `${constants.appUrl}${props.internalPath}`;
		isExternal = false;
		isBreakingNews = props.isBreakingNews;
		modulesTypes = props.modulesTypes;
	} else {
		href = props.externalUrl;
		trackingUrl = props.externalUrl;
		isExternal = true;
		isBreakingNews = false;
		modulesTypes = [];
	}

	return (
		<Tracktor key={trackingUrl}>
			{({ trackEvent }) => {
				const onClickHandler = () =>
					trackEvent({
						data: {
							target: { title, url: trackingUrl },
							type: trackEventDataType,
						},
						event: { type: 'PublisherClick' },
					});

				return (
					<Link
						className={`aitl-gtm ${styles.link} ${IMAGE_ZOOM_ON_HOVER_CLASS} ${className ?? ''}`}
						href={href}
						onClick={onClickHandler}
						target={isExternal ? '_blank' : undefined}
					>
						<article className={styles.wrapper}>
							<div className={styles.image}>{image}</div>

							<ArticleItemBody className={styles.body}>
								<header className={styles.header}>
									<section className={`${styles.metaModules} body6`}>
										<ArticleItemPublicationDate publicationDate={publicationDate} />

										<ArticleItemModuleTypes
											className={styles.moduleType}
											modulesTypes={modulesTypes}
											isExternal={isExternal}
											isBreakingNews={isBreakingNews}
										/>
									</section>

									<As className={`heading1 ${styles.title}`}>{title}</As>
								</header>

								<section>
									<ArticleItemTeaserText className={styles.teaserText}>{teaserText}</ArticleItemTeaserText>{' '}
								</section>
							</ArticleItemBody>
						</article>
					</Link>
				);
			}}
		</Tracktor>
	);
}
