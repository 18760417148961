import { ThemeSelectorFooter } from '@/components/ThemeSelector/ThemeSelectorFooter';
import { NavigationList } from '@/cutils/page-context/navigation';

import { BRLinks } from '../BRLinks';
import { rubrikenLinks, mediaLinks } from '../Footer.utils';
import { ScrollToTop } from '../ScrollToTop';
import { AppSection } from '../Section/AppSection';
import { LinkSection } from '../Section/LinkSection';
import { NewsletterSection } from '../Section/NewsletterSection';
import { Section } from '../Section/Section';
import { Social } from '../Social';

import styles from './FooterDesktop.module.scss';

type Props = {
	className?: string;
	bayernNavigation: NavigationList;
	renderChildren: boolean;
};

export function FooterDesktop({ bayernNavigation, className = '', renderChildren }: Props) {
	const isDesktop = true;

	return (
		<footer className={`${className} ${styles.footer}`} data-t-element="footer_navigation">
			{renderChildren ? (
				<>
					<ScrollToTop className={styles.scrollTop} />
					<Social className={styles.socialSection} />
					<LinkSection title="Bayern" links={bayernNavigation} className={styles.bayernSection} expandable={!isDesktop} />
					<LinkSection title="Rubriken" links={rubrikenLinks} className={styles.rubrikenSection} expandable={!isDesktop} />
					<LinkSection title="Medien" links={mediaLinks} className={styles.medienSection} expandable={!isDesktop} />
					<NewsletterSection className={styles.newsletterSection} />
					<section className={styles.appTheme}>
						<AppSection />
						<Section title="Farbschema" expandable={false}>
							<ThemeSelectorFooter forceTheme="dark" />
						</Section>
					</section>
					<BRLinks className={styles.impressumSection} />
				</>
			) : null}
		</footer>
	);
}
