import type { PublisherClickVariant, PublisherEventVariant, PublisherImpressionVariant, TrackingEventHandler } from '@/types/tracking';
import { logger } from '@/utils/logging/logger';

import { buildSectionPlacementVariable } from '../utils';

const lastPublisherEventMap: Map<string, string> = new Map();

export const createPublisherEvent: TrackingEventHandler<
	PublisherClickVariant | PublisherEventVariant | PublisherImpressionVariant,
	{ name: string; payload: Record<string, any> } | null
> = (trackEvent, options) => {
	const { data, event } = trackEvent;

	const { customVars, target } = data || { customVars: {}, target: {} };

	const sectionPlacementVariable = buildSectionPlacementVariable(data);

	const payload = {
		// Event-Typ
		campaignId: `[BR24 ${data.type}]`,
		// ID: x12 der aktuellen Seite.
		creation: customVars?.['12'],
		// Seitentitel: x8 der aktuellen Seite.
		variant: customVars?.['8'],
		// Item-Typ
		format: (data.itemType && `[${data.itemType}]`) || null,
		// MV Test Variante
		generalPlacement: null as null | string,
		// Section-Nummer + Item-Nummer -> Position im Format SS-I
		detailedPlacement: (sectionPlacementVariable && `[${sectionPlacementVariable}]`) || null,
		// Ziel - Seitentitel
		advertiserId: (target && target.title && `[${encodeURIComponent(target.title)}]`) || null,
		// Ziel - URL
		url: (target && target.url && `[${encodeURIComponent(target.url)}]`) || null,
	};

	let name = 'impression';

	if (event.type === 'PublisherClick') {
		name = 'click';

		if (options?.featureFlags?.abcTest?.variant) {
			payload.generalPlacement = `[Variante ${options?.featureFlags.abcTest.variant}]`;
		}
	}

	// siehe https://confluence.ard.de/pages/viewpage.action?pageId=241990785

	// Workaround for duplicate/tripple "BR24 Artikel gelesen" events, when clicking "scroll to comments" button https://jira.ard.de/browse/BR24-1058
	const eventString = JSON.stringify(payload);

	const lastPublisherEventByTracker = lastPublisherEventMap.get(options.trackerId);

	if (lastPublisherEventByTracker && lastPublisherEventByTracker === eventString) {
		return null;
	}

	if (typeof window !== 'undefined' && (window as any).debug) {
		// eslint-disable-next-line no-console
		logger.info('publisher event:', name, payload, data);
	}

	lastPublisherEventMap.set(options.trackerId, eventString);

	return {
		payload,
		name: event?.type === 'PublisherClick' ? 'click' : 'impression',
	};
};
