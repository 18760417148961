export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		ImageInterface: ['ModuleImage'],
		MangoAgeRestrictedThingInterface: [
			'MangoAgeRestrictedThing',
			'MangoBestOf',
			'MangoClip',
			'MangoExtra',
			'MangoItem',
			'MangoMakingOf',
			'MangoProgramme',
			'MangoTrailer',
		],
		MangoAgentInterface: [
			'MangoAgent',
			'MangoBroadcastService',
			'MangoDepartment',
			'MangoEndUser',
			'MangoGSEA',
			'MangoLRA',
			'MangoOrganization',
			'MangoOrganizationalUnit',
			'MangoPerson',
			'MangoPublicationService',
			'MangoUser',
		],
		MangoAudienceInterface: ['MangoAudience'],
		MangoAudioFileInterface: ['MangoAudioFile'],
		MangoAudioProfileInterface: ['MangoAudioProfile'],
		MangoAvailabilityCategoryInterface: ['MangoAvailabilityCategory'],
		MangoBannerContentInterface: ['MangoBannerContent'],
		MangoBestOfInterface: ['MangoBestOf'],
		MangoBoardInterface: ['MangoBoard'],
		MangoBroadcastEventInterface: ['MangoBroadcastEvent'],
		MangoBroadcastRegionInterface: ['MangoBroadcastRegion'],
		MangoBroadcastServiceInterface: ['MangoBroadcastService'],
		MangoCaptionInterface: ['MangoCaption'],
		MangoCategorizationInterface: [
			'MangoAudience',
			'MangoBestOf',
			'MangoBoard',
			'MangoCategorization',
			'MangoCategory',
			'MangoClip',
			'MangoCreativeWork',
			'MangoExtra',
			'MangoFormat',
			'MangoGenre',
			'MangoGrouping',
			'MangoImage',
			'MangoItem',
			'MangoMakingOf',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoSeason',
			'MangoSeries',
			'MangoSubject',
			'MangoTag',
			'MangoTrailer',
			'MangoWebPage',
		],
		MangoCategoryInterface: ['MangoCategory'],
		MangoClipInterface: ['MangoBestOf', 'MangoClip', 'MangoExtra', 'MangoItem', 'MangoMakingOf', 'MangoProgramme', 'MangoTrailer'],
		MangoCollationInterface: ['MangoCollation'],
		MangoContentInteractionInterface: ['MangoContentInteraction'],
		MangoCreativeWorkInterface: [
			'MangoBestOf',
			'MangoBoard',
			'MangoClip',
			'MangoCreativeWork',
			'MangoExtra',
			'MangoGrouping',
			'MangoImage',
			'MangoItem',
			'MangoMakingOf',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoSeason',
			'MangoSeries',
			'MangoTrailer',
			'MangoWebPage',
		],
		MangoDeletableThingInterface: ['MangoContentInteraction', 'MangoDeletableThing', 'MangoSection'],
		MangoDepartmentInterface: ['MangoDepartment'],
		MangoElementContentInterface: ['MangoBannerContent', 'MangoElementContent', 'MangoTeaserContent'],
		MangoEmbeddableThingInterface: [
			'MangoBestOf',
			'MangoClip',
			'MangoEmbeddableThing',
			'MangoExtra',
			'MangoItem',
			'MangoMakingOf',
			'MangoProgramme',
			'MangoTrailer',
		],
		MangoEndUserInterface: ['MangoEndUser'],
		MangoExternalURLInterface: ['MangoExternalURL', 'MangoFacebookAccount', 'MangoSocialMediaAccount', 'MangoTwitterAccount'],
		MangoExtraInterface: ['MangoBestOf', 'MangoExtra', 'MangoMakingOf', 'MangoTrailer'],
		MangoFacebookAccountInterface: ['MangoFacebookAccount'],
		MangoFileInterface: [
			'MangoAudioFile',
			'MangoFile',
			'MangoImageFile',
			'MangoLivestreamResource',
			'MangoTimedTextFile',
			'MangoVideoFile',
		],
		MangoFormatInterface: ['MangoFormat'],
		MangoGSEAInterface: ['MangoGSEA'],
		MangoGenreInterface: ['MangoGenre'],
		MangoGeoZoneInterface: ['MangoGeoZone'],
		MangoGroupingInterface: ['MangoGrouping', 'MangoPlaylist', 'MangoProgramme', 'MangoSeason', 'MangoSeries'],
		MangoImageFileCropInterface: ['MangoImageFileCrop'],
		MangoImageFileInterface: ['MangoImageFile'],
		MangoImageInterface: ['MangoImage'],
		MangoImportLocatorInterface: ['MangoImportLocator'],
		MangoImportableThingInterface: [
			'MangoAudioFile',
			'MangoBestOf',
			'MangoBoard',
			'MangoBroadcastEvent',
			'MangoClip',
			'MangoEndUser',
			'MangoExtra',
			'MangoFile',
			'MangoImage',
			'MangoImageFile',
			'MangoImportableThing',
			'MangoItem',
			'MangoLivestream',
			'MangoLivestreamResource',
			'MangoMakingOf',
			'MangoPerson',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoSeason',
			'MangoSeries',
			'MangoTimedTextFile',
			'MangoTrailer',
			'MangoUser',
			'MangoVideoFile',
			'MangoWebPage',
		],
		MangoItemInterface: ['MangoItem'],
		MangoLRAInterface: ['MangoLRA'],
		MangoLinkableThingInterface: [
			'MangoBestOf',
			'MangoClip',
			'MangoEndUser',
			'MangoExtra',
			'MangoImage',
			'MangoItem',
			'MangoLinkableThing',
			'MangoLivestream',
			'MangoMakingOf',
			'MangoPerson',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoSeason',
			'MangoSeries',
			'MangoTrailer',
			'MangoUser',
		],
		MangoLivestreamInterface: ['MangoLivestream'],
		MangoLivestreamResourceInterface: ['MangoLivestreamResource'],
		MangoLocatorInterface: ['MangoImportLocator', 'MangoLocator'],
		MangoMakingOfInterface: ['MangoMakingOf'],
		MangoOrganizationInterface: [
			'MangoBroadcastService',
			'MangoDepartment',
			'MangoGSEA',
			'MangoLRA',
			'MangoOrganization',
			'MangoOrganizationalUnit',
			'MangoPublicationService',
		],
		MangoOrganizationalUnitInterface: ['MangoDepartment', 'MangoOrganizationalUnit'],
		MangoPersonInterface: ['MangoEndUser', 'MangoPerson', 'MangoUser'],
		MangoPlaylistInterface: ['MangoPlaylist'],
		MangoProfileInterface: ['MangoAudioProfile', 'MangoProfile', 'MangoVideoProfile'],
		MangoProgrammeInterface: ['MangoProgramme'],
		MangoPublicationEventInterface: ['MangoBroadcastEvent', 'MangoPublicationEvent'],
		MangoPublicationServiceInterface: ['MangoBroadcastService', 'MangoPublicationService'],
		MangoPublishableThingInterface: [
			'MangoBestOf',
			'MangoBoard',
			'MangoClip',
			'MangoExtra',
			'MangoImage',
			'MangoItem',
			'MangoMakingOf',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoPublishableThing',
			'MangoSeason',
			'MangoSeries',
			'MangoTrailer',
			'MangoWebPage',
		],
		MangoReactionTypeInterface: ['MangoReactionType'],
		MangoSeasonInterface: ['MangoSeason'],
		MangoSectionInterface: ['MangoSection'],
		MangoSectionTemplateInterface: ['MangoSectionTemplate'],
		MangoSeriesInterface: ['MangoSeries'],
		MangoShortlyAvailableThingInterface: [
			'MangoBestOf',
			'MangoClip',
			'MangoExtra',
			'MangoItem',
			'MangoMakingOf',
			'MangoProgramme',
			'MangoShortlyAvailableThing',
			'MangoTrailer',
		],
		MangoSocialMediaAccountInterface: ['MangoFacebookAccount', 'MangoSocialMediaAccount', 'MangoTwitterAccount'],
		MangoStatusInterface: ['MangoStatus'],
		MangoSubjectInterface: [
			'MangoBestOf',
			'MangoBoard',
			'MangoClip',
			'MangoCreativeWork',
			'MangoExtra',
			'MangoGrouping',
			'MangoImage',
			'MangoItem',
			'MangoMakingOf',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoSeason',
			'MangoSeries',
			'MangoSubject',
			'MangoTrailer',
			'MangoWebPage',
		],
		MangoSubtitleInterface: ['MangoCaption', 'MangoSubtitle'],
		MangoSystemInterface: ['MangoSystem'],
		MangoTagInterface: ['MangoTag'],
		MangoTeaserContentInterface: ['MangoTeaserContent'],
		MangoThingWithCanonicalUrlInterface: [
			'MangoBestOf',
			'MangoBoard',
			'MangoClip',
			'MangoExtra',
			'MangoItem',
			'MangoLivestream',
			'MangoMakingOf',
			'MangoProgramme',
			'MangoSeries',
			'MangoThingWithCanonicalUrl',
			'MangoTrailer',
			'MangoWebPage',
		],
		MangoThingWithRelevancePeriodInterface: [
			'MangoBestOf',
			'MangoClip',
			'MangoExtra',
			'MangoItem',
			'MangoMakingOf',
			'MangoProgramme',
			'MangoThingWithRelevancePeriod',
			'MangoTrailer',
		],
		MangoTimedTextFileInterface: ['MangoTimedTextFile'],
		MangoTrackableThingInterface: [
			'MangoBestOf',
			'MangoBoard',
			'MangoBroadcastEvent',
			'MangoClip',
			'MangoExtra',
			'MangoItem',
			'MangoMakingOf',
			'MangoProgramme',
			'MangoSeries',
			'MangoTrackableThing',
			'MangoTrailer',
		],
		MangoTrackingInfoInterface: ['MangoTrackingInfo'],
		MangoTrailerInterface: ['MangoTrailer'],
		MangoTwitterAccountInterface: ['MangoTwitterAccount'],
		MangoUserInterface: ['MangoEndUser', 'MangoUser'],
		MangoVersionedThingInterface: [
			'MangoBestOf',
			'MangoClip',
			'MangoExtra',
			'MangoImage',
			'MangoItem',
			'MangoMakingOf',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoSeason',
			'MangoSeries',
			'MangoTrailer',
			'MangoVersionedThing',
		],
		MangoVideoFileInterface: ['MangoVideoFile'],
		MangoVideoProfileInterface: ['MangoVideoProfile'],
		MangoWebPageInterface: ['MangoWebPage'],
		MangoWebhookInterface: ['MangoWebhook'],
		MangoWritableThingInterface: [
			'MangoAudioFile',
			'MangoBannerContent',
			'MangoBestOf',
			'MangoBoard',
			'MangoBroadcastEvent',
			'MangoBroadcastService',
			'MangoCaption',
			'MangoClip',
			'MangoContentInteraction',
			'MangoElementContent',
			'MangoEndUser',
			'MangoExternalURL',
			'MangoExtra',
			'MangoFacebookAccount',
			'MangoFile',
			'MangoImage',
			'MangoImageFile',
			'MangoImageFileCrop',
			'MangoImportLocator',
			'MangoImportableThing',
			'MangoItem',
			'MangoLivestream',
			'MangoLivestreamResource',
			'MangoLocator',
			'MangoMakingOf',
			'MangoPerson',
			'MangoPlaylist',
			'MangoProgramme',
			'MangoPublicationEvent',
			'MangoSeason',
			'MangoSection',
			'MangoSectionTemplate',
			'MangoSeries',
			'MangoSocialMediaAccount',
			'MangoSubtitle',
			'MangoTeaserContent',
			'MangoTimedTextFile',
			'MangoTrackingInfo',
			'MangoTrailer',
			'MangoTwitterAccount',
			'MangoUser',
			'MangoVideoFile',
			'MangoWebPage',
			'MangoWebhook',
			'MangoWritableThing',
		],
		Node: [
			'Article',
			'ArticlesAuthor',
			'Author',
			'AuthorStatus',
			'Board',
			'BoardsSection',
			'BoardsSectionsItem',
			'BoardsTeaser',
			'Category',
			'Config',
			'EmbedInstance',
			'EmbedService',
			'History',
			'MangoAgeRestrictedThing',
			'MangoAgent',
			'MangoAudience',
			'MangoAudioFile',
			'MangoAudioProfile',
			'MangoAvailabilityCategory',
			'MangoBannerContent',
			'MangoBestOf',
			'MangoBoard',
			'MangoBroadcastEvent',
			'MangoBroadcastRegion',
			'MangoBroadcastService',
			'MangoCaption',
			'MangoCategorization',
			'MangoCategory',
			'MangoClip',
			'MangoCollation',
			'MangoContentInteraction',
			'MangoCreativeWork',
			'MangoDeletableThing',
			'MangoDepartment',
			'MangoElementContent',
			'MangoEmbeddableThing',
			'MangoEndUser',
			'MangoExternalURL',
			'MangoExtra',
			'MangoFacebookAccount',
			'MangoFile',
			'MangoFormat',
			'MangoGSEA',
			'MangoGenre',
			'MangoGeoZone',
			'MangoGrouping',
			'MangoImage',
			'MangoImageFile',
			'MangoImageFileCrop',
			'MangoImportLocator',
			'MangoImportableThing',
			'MangoItem',
			'MangoLRA',
			'MangoLinkableThing',
			'MangoLivestream',
			'MangoLivestreamResource',
			'MangoLocator',
			'MangoMakingOf',
			'MangoOrganization',
			'MangoOrganizationalUnit',
			'MangoPerson',
			'MangoPlaylist',
			'MangoProfile',
			'MangoProgramme',
			'MangoPublicationEvent',
			'MangoPublicationService',
			'MangoPublishableThing',
			'MangoReactionType',
			'MangoSeason',
			'MangoSection',
			'MangoSectionTemplate',
			'MangoSeries',
			'MangoShortlyAvailableThing',
			'MangoSocialMediaAccount',
			'MangoStatus',
			'MangoSubject',
			'MangoSubtitle',
			'MangoSystem',
			'MangoTag',
			'MangoTeaserContent',
			'MangoThingWithCanonicalUrl',
			'MangoThingWithRelevancePeriod',
			'MangoTimedTextFile',
			'MangoTrackableThing',
			'MangoTrackingInfo',
			'MangoTrailer',
			'MangoTwitterAccount',
			'MangoUser',
			'MangoVersionedThing',
			'MangoVideoFile',
			'MangoVideoProfile',
			'MangoViewer',
			'MangoWebPage',
			'MangoWebhook',
			'MangoWritableThing',
			'Module',
			'Navigation',
			'News',
			'OMAgeRestrictedThing',
			'OMAgent',
			'OMAudience',
			'OMAudioFile',
			'OMAudioProfile',
			'OMBestOf',
			'OMBroadcastEvent',
			'OMBroadcastRegion',
			'OMBroadcastService',
			'OMCaption',
			'OMCategorization',
			'OMCategory',
			'OMClip',
			'OMCollation',
			'OMCreativeWork',
			'OMDeletableThing',
			'OMDeleteableThing',
			'OMDepartment',
			'OMEmbeddableThing',
			'OMExternalURL',
			'OMExtra',
			'OMFacebookAccount',
			'OMFile',
			'OMFormat',
			'OMGSEA',
			'OMGenre',
			'OMGeoZone',
			'OMGrouping',
			'OMImage',
			'OMImageFile',
			'OMImageFileCrop',
			'OMImportLocator',
			'OMImportableThing',
			'OMItem',
			'OMLRA',
			'OMLinkableThing',
			'OMLivestream',
			'OMLivestreamResource',
			'OMLocator',
			'OMMakingOf',
			'OMOrganization',
			'OMOrganizationalUnit',
			'OMPerson',
			'OMPlaylist',
			'OMProfile',
			'OMProgramme',
			'OMPublicationEvent',
			'OMPublicationService',
			'OMSeason',
			'OMSeries',
			'OMSocialMediaAccount',
			'OMSubject',
			'OMSubtitle',
			'OMSystem',
			'OMTag',
			'OMThingWithCanonicalUrl',
			'OMTimedTextFile',
			'OMTrailer',
			'OMTwitterAccount',
			'OMUser',
			'OMVideoFile',
			'OMVideoProfile',
			'OMViewer',
			'OMWebPage',
			'OMWebhook',
			'OMWritableThing',
			'OneSignalNotification',
			'Pgmigration',
			'PushNotificationConfig',
			'PushServiceNotification',
			'Query',
			'Redirection',
			'ShortnewsItem',
			'ShortnewsList',
			'Tag',
		],
		OMAgeRestrictedThingInterface: [
			'OMAgeRestrictedThing',
			'OMBestOf',
			'OMClip',
			'OMExtra',
			'OMItem',
			'OMMakingOf',
			'OMProgramme',
			'OMTrailer',
		],
		OMAgentInterface: [
			'OMAgent',
			'OMBroadcastService',
			'OMDepartment',
			'OMGSEA',
			'OMLRA',
			'OMOrganization',
			'OMOrganizationalUnit',
			'OMPerson',
			'OMPublicationService',
			'OMUser',
		],
		OMAudienceInterface: ['OMAudience'],
		OMAudioFileInterface: ['OMAudioFile'],
		OMAudioProfileInterface: ['OMAudioProfile'],
		OMBestOfInterface: ['OMBestOf'],
		OMBroadcastEventInterface: ['OMBroadcastEvent'],
		OMBroadcastRegionInterface: ['OMBroadcastRegion'],
		OMBroadcastServiceInterface: ['OMBroadcastService'],
		OMCaptionInterface: ['OMCaption'],
		OMCategorizationInterface: [
			'OMAudience',
			'OMBestOf',
			'OMCategorization',
			'OMCategory',
			'OMClip',
			'OMCreativeWork',
			'OMExtra',
			'OMFormat',
			'OMGenre',
			'OMGrouping',
			'OMImage',
			'OMItem',
			'OMMakingOf',
			'OMPlaylist',
			'OMProgramme',
			'OMSeason',
			'OMSeries',
			'OMSubject',
			'OMTag',
			'OMTrailer',
			'OMWebPage',
		],
		OMCategoryInterface: ['OMCategory'],
		OMClipInterface: ['OMBestOf', 'OMClip', 'OMExtra', 'OMItem', 'OMMakingOf', 'OMProgramme', 'OMTrailer'],
		OMCollationInterface: ['OMCollation'],
		OMCreativeWorkInterface: [
			'OMBestOf',
			'OMClip',
			'OMCreativeWork',
			'OMExtra',
			'OMGrouping',
			'OMImage',
			'OMItem',
			'OMMakingOf',
			'OMPlaylist',
			'OMProgramme',
			'OMSeason',
			'OMSeries',
			'OMTrailer',
			'OMWebPage',
		],
		OMDeletableThingInterface: ['OMDeletableThing'],
		OMDeleteableThingInterface: ['OMDeleteableThing', 'ShortnewsItem', 'ShortnewsList'],
		OMDepartmentInterface: ['OMDepartment'],
		OMEmbeddableThingInterface: ['OMBestOf', 'OMClip', 'OMEmbeddableThing', 'OMExtra', 'OMItem', 'OMMakingOf', 'OMProgramme', 'OMTrailer'],
		OMExternalURLInterface: ['OMExternalURL', 'OMFacebookAccount', 'OMSocialMediaAccount', 'OMTwitterAccount'],
		OMExtraInterface: ['OMBestOf', 'OMExtra', 'OMMakingOf', 'OMTrailer'],
		OMFacebookAccountInterface: ['OMFacebookAccount'],
		OMFileInterface: ['OMAudioFile', 'OMFile', 'OMImageFile', 'OMLivestreamResource', 'OMTimedTextFile', 'OMVideoFile'],
		OMFormatInterface: ['OMFormat'],
		OMGSEAInterface: ['OMGSEA'],
		OMGenreInterface: ['OMGenre'],
		OMGeoZoneInterface: ['OMGeoZone'],
		OMGroupingInterface: ['OMGrouping', 'OMPlaylist', 'OMProgramme', 'OMSeason', 'OMSeries'],
		OMImageFileCropInterface: ['OMImageFileCrop'],
		OMImageFileInterface: ['OMImageFile'],
		OMImageInterface: ['OMImage'],
		OMImportLocatorInterface: ['OMImportLocator'],
		OMImportableThingInterface: ['OMImportableThing', 'ShortnewsItem', 'ShortnewsList'],
		OMItemInterface: ['OMItem'],
		OMLRAInterface: ['OMLRA'],
		OMLinkableThingInterface: ['OMLinkableThing'],
		OMLivestreamInterface: ['OMLivestream'],
		OMLivestreamResourceInterface: ['OMLivestreamResource'],
		OMLocatorInterface: ['OMImportLocator', 'OMLocator'],
		OMMakingOfInterface: ['OMMakingOf'],
		OMOrganizationInterface: [
			'OMBroadcastService',
			'OMDepartment',
			'OMGSEA',
			'OMLRA',
			'OMOrganization',
			'OMOrganizationalUnit',
			'OMPublicationService',
		],
		OMOrganizationalUnitInterface: ['OMDepartment', 'OMOrganizationalUnit'],
		OMPersonInterface: ['OMPerson', 'OMUser'],
		OMPlaylistInterface: ['OMPlaylist'],
		OMProfileInterface: ['OMAudioProfile', 'OMProfile', 'OMVideoProfile'],
		OMProgrammeInterface: ['OMProgramme'],
		OMPublicationEventInterface: ['OMBroadcastEvent', 'OMPublicationEvent'],
		OMPublicationServiceInterface: ['OMBroadcastService', 'OMPublicationService'],
		OMSeasonInterface: ['OMSeason'],
		OMSeriesInterface: ['OMSeries'],
		OMSocialMediaAccountInterface: ['OMFacebookAccount', 'OMSocialMediaAccount', 'OMTwitterAccount'],
		OMSubjectInterface: [
			'OMBestOf',
			'OMClip',
			'OMCreativeWork',
			'OMExtra',
			'OMGrouping',
			'OMImage',
			'OMItem',
			'OMMakingOf',
			'OMPlaylist',
			'OMProgramme',
			'OMSeason',
			'OMSeries',
			'OMSubject',
			'OMTrailer',
			'OMWebPage',
		],
		OMSubtitleInterface: ['OMCaption', 'OMSubtitle'],
		OMSystemInterface: ['OMSystem'],
		OMTagInterface: ['OMTag'],
		OMThingWithCanonicalUrlInterface: [
			'OMBestOf',
			'OMClip',
			'OMExtra',
			'OMItem',
			'OMLivestream',
			'OMMakingOf',
			'OMProgramme',
			'OMSeries',
			'OMThingWithCanonicalUrl',
			'OMTrailer',
			'OMWebPage',
		],
		OMTimedTextFileInterface: ['OMTimedTextFile'],
		OMTrailerInterface: ['OMTrailer'],
		OMTwitterAccountInterface: ['OMTwitterAccount'],
		OMUserInterface: ['OMUser'],
		OMVideoFileInterface: ['OMVideoFile'],
		OMVideoProfileInterface: ['OMVideoProfile'],
		OMWebPageInterface: ['OMWebPage'],
		OMWebhookInterface: ['OMWebhook'],
		OMWritableThingInterface: [
			'OMImportLocator',
			'OMImportableThing',
			'OMLocator',
			'OMWebhook',
			'OMWritableThing',
			'ShortnewsItem',
			'ShortnewsList',
		],
		ShortnewsInterface: ['ShortnewsItem'],
		ShortnewsListInterface: ['ShortnewsList'],
		SourceOrigin: ['MangoSeries'],
		TeaserInterface: ['Article'],
		TrackingInterface: ['Article', 'Author', 'Board', 'MangoSeries', 'Module', 'ShortnewsItem'],
		WithCache: ['Article', 'Author', 'Board'],
	},
};
export default result;
