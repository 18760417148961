import { CSSProperties } from 'react';

import { IconSystem } from '../../../icons/IconSystem';
import { IconVariantMap } from '../../../icons/icons-system';

import styles from './Tooltip.module.scss';

type Props = {
	content: string;
	type: 'info' | 'success' | 'error';
	className?: string;
	style?: CSSProperties | undefined;
};

export function Tooltip({ type, content, className, style: sectionStyle }: Props) {
	let icon: keyof IconVariantMap;
	let title: string;
	let style: string | undefined;
	switch (type) {
		case 'info':
			icon = 'info-circle';
			style = undefined;
			title = 'Information';
			break;

		case 'success':
			icon = 'check-circle';
			style = styles.success;
			title = 'Erfolg';
			break;

		case 'error':
			icon = 'close-circle';
			style = undefined;
			title = 'Fehler';
			break;
	}

	return (
		<section className={`${styles.tooltip} body7 ${style ?? ''}  ${className ?? ''}`} style={sectionStyle}>
			<IconSystem icon={icon} variant="filled" title={title} size="xs" />
			<div>{content}</div>
		</section>
	);
}
