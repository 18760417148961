import { BreakpointMedia } from '@/cutils/breakpoints/media';
import { NavigationList } from '@/cutils/page-context/navigation';

import { FooterDesktop } from './Content/FooterDesktop';
import { FooterMobile } from './Content/FooterMobile';
import styles from './Footer.module.scss';

type Props = {
	bayernNavigation: NavigationList;
};

export function Footer({ bayernNavigation }: Props) {
	return (
		<>
			<BreakpointMedia lessThan="lg">
				{(className, renderChildren) => {
					return (
						<FooterMobile
							className={`${className} ${styles.footer} heading4`}
							bayernNavigation={bayernNavigation}
							renderChildren={renderChildren}
						/>
					);
				}}
			</BreakpointMedia>
			<BreakpointMedia greaterThanOrEqual="lg">
				{(className, renderChildren) => {
					return (
						<FooterDesktop
							className={`${className} ${styles.footer} heading4`}
							bayernNavigation={bayernNavigation}
							renderChildren={renderChildren}
						/>
					);
				}}
			</BreakpointMedia>
		</>
	);
}
