import React from 'react';

import { TracktorContext } from './TracktorContext';
import { TrackEventFunction, TrackingData, UseTracktorProps } from './types';
import computeTrackingData from './utils/computeTrackingData';
import useOnIntersect from './utils/useOnIntersect';
import wrapFunction from './utils/wrapFunction';

const useTracktor = ({
	eventData,
	intersectionOptions = { triggerOnce: true },
	pageViewData,
	trackingData: ownData = {},
}: UseTracktorProps = {}) => {
	// Get the built `trackingData` and the `dispatcher` from our context.
	const { data, dispatcher } = React.useContext(TracktorContext);

	React.useEffect(() => {
		if (!!pageViewData && !dispatcher.isDefault) {
			// Call the `dispatcher` with the provided `pageViewData`.
			dispatcher(pageViewData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Computes the tracking data, and calls the `dispatcher` with it.

	const trackEvent: TrackEventFunction = (stateOrMergeStateFn) => {
		let _stateOrMergeStateFn = stateOrMergeStateFn;

		/* this allows trackEvent to be directly used e.g. as onClick handler */
		if (
			(_stateOrMergeStateFn && 'nativeEvent' in _stateOrMergeStateFn && _stateOrMergeStateFn.nativeEvent instanceof Event) ||
			_stateOrMergeStateFn instanceof Event
		) {
			_stateOrMergeStateFn = undefined;
		}

		if (typeof _stateOrMergeStateFn === 'function') {
			const contextData = computeTrackingData(data, ownData, {});
			const mergedTrackingData = _stateOrMergeStateFn(contextData);
			dispatcher(mergedTrackingData);
		} else {
			const mergedTrackingData = computeTrackingData(data, ownData, _stateOrMergeStateFn || {});
			dispatcher(mergedTrackingData);
		}
	};

	// This higher-order function sets the `trackEventData` and also checks whether the library-consumer
	// defined the `eventData` which is used inside this function.
	const createTrackEvent =
		(trackEventData: TrackingData = {}) =>
		() => {
			if (!eventData && process.env.NODE_ENV !== 'production') {
				throw new Error('If the `onClickWrapper` is used, the `eventData` prop has to be defined.');
			}

			trackEvent(trackEventData || {});
		};

	// This enables the consumer to wrap a component's `onClick` prop to also fire the tracking event.
	const onClickWrapper = wrapFunction(createTrackEvent(eventData));

	// Will be called when consumer puts the `ref` on a component and it scrolls into view.
	const handleIntersection = () => {
		if (!eventData && process.env.NODE_ENV !== 'production') {
			throw new Error('If the `intersectionRef` is used, the `eventData` prop has to be defined.');
		}

		trackEvent(eventData || {});
	};

	const [intersectionRef] = useOnIntersect({ callback: handleIntersection, options: intersectionOptions });

	return {
		createTrackEvent,
		intersectionRef,
		onClickWrapper,
		/**
		 * if an object is provided, we deep merge it when withoutContext is falsy.
		 * If a function is provided, merging has to be done manually by the user through this function based on given state.
		 */
		trackEvent,
	};
};

export default useTracktor;
