import { useMemo } from 'react';

import { Props as ImageProps, Image } from './Image';
import { ImageModuleFragment } from './__generated__/ImageModule.generated';

type ImageContainerImageModule = Omit<ImageModuleFragment, '__typename'> | null;

function mapModuleImageToHTMLImageAttributes(image: ImageContainerImageModule | undefined) {
	const alt = image?.altText ?? '';
	const title = image?.title ?? undefined;
	const copyright = image?.copyright ?? undefined;
	const src = image?.url ?? undefined;

	// do not use title as an alternative to alt (see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img)
	return { alt, title: title === alt ? undefined : title, src, copyright };
}

/**
 * Image is used as a graphql data loader for image modules that are being served from br24 graphql api
 * It consolidates properties and passes them on to the Image component in `/client/components/image/
 *
 * For usage of images, read the docs of the Image component
 */
export function ImageModule({
	image,
	...imageProps
}: Omit<ImageProps, 'alt' | 'title' | 'src' | 'copyright'> & { image: ImageContainerImageModule | undefined }) {
	const htmlImageAttributes = mapModuleImageToHTMLImageAttributes(image);

	const ImageComp = useMemo(
		() => (
			<Image
				alt={htmlImageAttributes.alt}
				title={htmlImageAttributes.title}
				src={htmlImageAttributes.src}
				copyright={htmlImageAttributes.copyright}
				{...imageProps}
			/>
		),
		[htmlImageAttributes.alt, htmlImageAttributes.copyright, htmlImageAttributes.src, htmlImageAttributes.title, imageProps]
	);

	return ImageComp;
}
