import { useResizeObserver } from '@react-hookz/web';
import { usePathname } from 'next/navigation';
import { MutableRefObject, memo, useCallback, useRef, useState } from 'react';

import { Link } from '@/components/Link/Link';
import { useBreakpoint } from '@/cutils/hooks/useBreakpoint';
import { NavigationList as NavigationListType } from '@/cutils/page-context/navigation';
import { Breakpoint } from '@/types/enums';

import styles from './HighlightNavigation.module.scss';

type Props = {
	highlightNavigation: NavigationListType;
	onBayernMenuClick?: () => void;
	bayernButtonRef: MutableRefObject<HTMLButtonElement | undefined>;
	className?: string;
	hideBayernMenu: boolean;
};

export const HighlightNavigation = memo(function HighlightNavigation({
	highlightNavigation,
	onBayernMenuClick,
	className,
	bayernButtonRef,
	hideBayernMenu,
}: Props) {
	const isDesktop = useBreakpoint(Breakpoint.TabletLarge);
	const navigationContainerRef = useRef<HTMLElement>(null);
	const elements = useRef<(HTMLElement | undefined)[]>([]);
	const [elementsHidden, setElementsHidden] = useState<boolean[]>([]);

	const registerNavigationItem = useCallback((element: HTMLElement | null, index: number) => {
		if (element) {
			elements.current[index] = element;
		} else {
			elements.current[index] = undefined;
		}
	}, []);

	// Hides invisible highlight navigation nodes to avoid for them being accessible via keyboard navigation
	useResizeObserver(
		navigationContainerRef,
		(event: ResizeObserverEntry) => {
			const { bottom: navigationBottom, right: navigationRight } = event.target.getBoundingClientRect();

			let hiddenStateChanged = false;
			const newState = new Array(elementsHidden.length);

			for (let i = 0; i < elements.current.length; i++) {
				const element = elements.current[i];
				if (element) {
					const { bottom, right } = element.getBoundingClientRect();
					if (right > navigationRight || bottom > navigationBottom) {
						newState[i] = true;
					} else {
						newState[i] = false;
					}

					hiddenStateChanged ||= newState[i] !== elementsHidden[i];
				}
			}

			if (hiddenStateChanged) {
				setElementsHidden(newState);
			}
		},
		isDesktop
	);

	const pathname = usePathname();
	const handleBayernButtonRef = (element: HTMLButtonElement) => {
		bayernButtonRef.current = element;
	};

	return (
		<nav
			className={`${styles.highlightNavigation} ${className ?? ''}`}
			ref={navigationContainerRef}
			// tracking attributes
			data-t-element="top_navigation"
			data-t-type="Highlightnavigation"
		>
			{!hideBayernMenu ? (
				<button className={`${styles.button} heading4`} onClick={onBayernMenuClick} ref={handleBayernButtonRef}>
					Bayern
				</button>
			) : null}
			{highlightNavigation.map((navigationNode, index) => {
				const isLinkActive = pathname === navigationNode.link;

				return (
					<Link
						href={navigationNode.link}
						key={navigationNode.id}
						className={`${styles.link} ${isLinkActive ? styles.active : ''} ${elementsHidden[index] ? styles.hidden : ''}`}
						ref={(element) => registerNavigationItem(element, index)}
						target={navigationNode.isExternal ? '_blank' : undefined}
					>
						{navigationNode.title}
					</Link>
				);
			})}
		</nav>
	);
});
