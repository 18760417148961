export function isLinkExternal(href: string, baseUrlRegexp: RegExp) {
	let isFullUrl = true;
	try {
		new URL(href);
	} catch (e) {
		isFullUrl = false;
	}

	return isFullUrl && !baseUrlRegexp.test(href);
}

export function removeAppUrlFromLink(url: string, baseUrlRegexp: RegExp) {
	return url.replace(baseUrlRegexp, '/');
}
